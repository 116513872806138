import React from 'react'
import { DateTime } from 'luxon'
import { Flex, Text } from '@/components/ui'
import { Badge } from '@/components/uiJar/badge'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import { ProposalCardWrapper } from './styles'

type ProposalCardProps = {
  onSelect: (proposal?: IProposal) => void
  proposal: IProposal
  index: number
}

const RenderBadge: React.FC<{ status: ProposalStatus }> = ({ status }) => {
  if (status === ProposalStatus.Sent || status === ProposalStatus.Updated) {
    return (
      <Badge variant="soft" colorTheme="accent" size={1}>
        New
      </Badge>
    )
  } else if (status === ProposalStatus.Accepted) {
    return (
      <Badge variant="soft" colorTheme="success" size={1}>
        Accepted
      </Badge>
    )
  } else if (status === ProposalStatus.Rejected) {
    return (
      <Badge variant="soft" colorTheme="danger" size={1}>
        Rejected
      </Badge>
    )
  } else if (status === ProposalStatus.Withdrawn) {
    return (
      <Badge variant="soft" colorTheme="neutral" size={1}>
        Withdrawn
      </Badge>
    )
  }
  return null
}

const ProposalCard: React.FC<ProposalCardProps> = ({
  onSelect,
  proposal,
  index,
}) => {
  const { status } = proposal
  const createdAt = DateTime.fromISO(proposal.createdAt)
  const updatedAt = DateTime.fromISO(proposal.updatedAt)
  return (
    <ProposalCardWrapper
      onClick={() => {
        onSelect(proposal)
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="medium-3">Proposal #{index}</Text>
        <RenderBadge status={status} />
      </Flex>
      <Flex gap={24} alignItems="center">
        <Flex gap={6} alignItems="center">
          <Text variant="regular-3" colorVar="neutral-11">
            Sent:
          </Text>
          <Text variant="medium-3">{createdAt.toFormat("d'th' MMMM")}</Text>
        </Flex>
        <Flex gap={6} alignItems="center">
          <Text variant="regular-3" colorVar="neutral-11">
            Accepted:
          </Text>
          <Text variant="medium-3">
            {status === ProposalStatus.Accepted
              ? updatedAt.toFormat("d'th' MMMM")
              : '--'}
          </Text>
        </Flex>
      </Flex>
    </ProposalCardWrapper>
  )
}

export default ProposalCard
