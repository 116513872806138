import styled from 'styled-components'
import { textVariants } from '@/components/ui/Text'
import { IconHolderProps, iconLeftCss } from '.'

export const DropdownIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: var(--neutral-12);
`

export const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const StyledIconHolder = styled.div<IconHolderProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${(p) =>
    p.focused ? 'var(--neutral-alpha-11)' : 'var(--neutral-alpha-9)'};
  z-index: 2;
  pointer-events: none;
  left: ${(p) => iconLeftCss[p.size || 2]};
`

export const SelectInputWrapper = styled.div`
  position: relative;
  &:hover {
    svg {
      color: var(--neutral-alpha-11);
    }
  }
`

export const SelectInputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.label`
  color: var(--neutral-12);
  ${textVariants['semibold-3']}
  margin-bottom: 8px;
`
export const OptionalLabel = styled.span`
  margin-left: 1px;
  font-weight: 500;
  color: var(--neutral-11);
`

export const HelperTextWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: ${(p) => (p.hasError ? 'var(--danger-11)' : 'var(--neutral-11)')};
`
export const HelperText = styled.span`
  ${textVariants['regular-2']}
  margin-left: 4px;
`
