import { DateTime } from 'luxon'
import { IChatV2 } from '@/models/IChatV2'
import { IUser } from '@/models/IUser'
class Helper {
  formatName = (fName?: string, lName?: string) => {
    const fullName =
      fName || lName ? `${fName || ''} ${lName || ''}`.trim() : null

    return fullName
  }

  formatTime = (time: string) => {
    const timeStamp = DateTime.fromISO(time)

    return timeStamp.toRelative()
  }

  initials = (fName: string, lName?: string, format = false) => {
    const name = format ? this.formatName(fName, lName) : fName

    return name?.slice(0, 2)
  }

  formatUsername = (username: string) => {
    return `@${username}`
  }

  isVideo = (mimeType: string) => {
    const videoTypes = [
      'video/x-flv',
      'video/mp4',
      'application/x-mpegURL',
      'video/MP2T',
      'video/3gpp',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
    ]

    return videoTypes.includes(mimeType)
  }

  pluralize = (
    count: number,
    noun: string,
    pluralNoun = '',
    suffix = 's',
    showCount = true,
  ) => {
    const properNoun = pluralNoun ? pluralNoun : noun

    const pluralized = `${showCount ? `${count} ` : ''}${
      count !== 1 ? `${properNoun}${suffix}` : noun
    }`

    return pluralized
  }

  pollPercentage = (totalVotes: number, votes: number) =>
    (votes / totalVotes) * 100

  hasExcerpt = (text: string, length = 300) => text && text.length > length

  getExcerpt = (text: string, enabled = true, maxLength = 300) => {
    const excerpt =
      this.hasExcerpt(text, maxLength) && enabled
        ? `${text.slice(0, maxLength)} ...`
        : text

    return excerpt
  }

  getAge = (time: string) => {
    const date = DateTime.fromISO(time)

    const age = DateTime.now().diff(date, ['years']).toObject()

    return age.years
  }

  returnFileURL = (file: File) => URL.createObjectURL(file)

  generateMentionMarkup = (id: string, username: string) => {
    return `@[${username}](${id})`
  }

  stringifyMention = (text?: string) => {
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g

    if (text) {
      const stringified = text.replace(mentionRegex, '@$1')

      return stringified
    }

    return ''
  }

  getParagraphs = (text: string) => text.split('\n')

  formatedTime = (date: number): string => {
    const now = new Date()
    const messageDate = new Date(date)
    const diffInSeconds = Math.floor(
      (now.getTime() - messageDate.getTime()) / 1000,
    )

    if (diffInSeconds < 60) return 'now'
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}min`
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 604800)}w`
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}m`
    return `${Math.floor(diffInSeconds / 31536000)}y`
  }

  getResourceUrl = (fileId: string, chatId: string, token: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE
    return `${baseUrl}/chats-v2/${chatId}/files/${fileId}?_auth=${token}`
  }

  getChatTitle = (chat: IChatV2, user: IUser) => {
    if (chat.participants?.length > 2) {
      return chat.title
    } else {
      const otherParticipant = chat.participants?.find(
        (participant) =>
          participant.user?.firstName !== user?.firstName ||
          participant.user?.lastName !== user?.lastName,
      )

      const firstName = otherParticipant?.user?.firstName || ''
      const lastName = otherParticipant?.user?.lastName || ''
      return `${firstName} ${lastName}`.trim()
    }
  }
}

export const helper = new Helper()
