import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { noop } from 'lodash'
import { IVisaWizardItem } from '@/models/IVisaWizardItem'
import { Flex, Icon, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { IFile, IFileRef } from '@/models/IFile'
import { PopupTopBar } from '@/components/uiJar/popup'
import { MobileOnlyContainer } from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import { IconLink } from '@/components/uiJar/IconLink'
import { InputSearchField } from '@/components/uiJar/InputSearchField'
import InputField from '@/components/uiJar/InputField'
import {
  DocumentPreview,
  FileContainer,
  PopupContent,
  PopupGrid,
  UploadSection,
  FileList,
  FileItem,
  FileName,
  MarkdownContent,
  FileField,
  PopupGridWrapper,
  Tab,
  PopupFooter,
  FilePreview,
  LabelFieldWrapper,
} from './styles'
import { PopupContainer } from './styles'
import { BaseWizardPopupProps } from '../types'

interface FileUploadPopupProp extends BaseWizardPopupProps {
  item?: IVisaWizardItem
  isViewer?: boolean
  isLabel?: boolean
  uploads?: IFile[]
  onSubmit: (files: IFileRef[], label?: string) => void
}

enum FileUploadTab {
  upload = 0,
  fileInfo = 1,
}

export const FileUploadPopup = ({
  item,
  uploads,
  isLabel = false,
  isViewer = false,
  onClose,
  onSubmit,
}: FileUploadPopupProp) => {
  const { api, status } = useApi()

  const [answerLabel, setAnswerLabel] = useState('')
  const [uploaded, setUploaded] = useState<IFile[]>(uploads || [])
  const [selected, setSelected] = useState<File[]>([])
  const [activeTab, setActiveTab] = useState<FileUploadTab>(
    FileUploadTab.upload,
  )

  const handleSubmit = async () => {
    if (uploaded?.length === 0 && selected.length === 0) return
    const previous = uploaded?.map((u) => ({
      id: u.id,
      name: u.name,
      mime: u.mime,
    }))
    if (uploaded.length > 0 && selected.length === 0) {
      return onSubmit(previous)
    }
    const formData = new FormData()
    selected.forEach((file) => formData.append('files[]', file))
    const files = await api.wizard.uploadFiles(formData).catch(noop)
    if (files) {
      onSubmit([...previous, ...files], answerLabel)
    }
  }

  const handleRemove = (index: number) => {
    const newUploaded = [...uploaded]
    newUploaded.splice(index, 1)
    setUploaded(newUploaded)
  }

  return (
    <PopupContainer>
      <PopupTopBar className="file-upload-topbar" onClose={onClose}>
        Upload file
      </PopupTopBar>
      <MobileOnlyContainer>
        <Flex gap={0}>
          <Tab
            onClick={() => setActiveTab(FileUploadTab.upload)}
            selected={activeTab === FileUploadTab.upload}
          >
            Upload
          </Tab>
          <Tab
            onClick={() => setActiveTab(FileUploadTab.fileInfo)}
            selected={activeTab === FileUploadTab.fileInfo}
          >
            File info
          </Tab>
        </Flex>
      </MobileOnlyContainer>

      <PopupGridWrapper>
        <PopupGrid>
          {item && (
            <DocumentPreview
              isViewer={isViewer}
              selectedTab={activeTab === FileUploadTab.fileInfo}
            >
              <PopupContent>
                <Flex direction="column" gap={20}>
                  {item?.title && (
                    <Text variant="semibold-4">{item.title}</Text>
                  )}
                  <MarkdownContent>
                    <ReactMarkdown linkTarget="_blank">{`${item?.description}`}</ReactMarkdown>
                  </MarkdownContent>
                </Flex>

                {item?.exampleFile && (
                  <FileContainer>
                    <FilePreview url={item?.exampleFile.path} />
                  </FileContainer>
                )}
              </PopupContent>
              <PopupFooter direction="column">
                {item && item.exampleFile && (
                  <a
                    href={item?.exampleFile.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      fullWidth
                      variant="outline"
                      size={3}
                      colorTheme="neutral"
                    >
                      View Example Document
                    </Button>
                  </a>
                )}
                {item.exampleTemplate && (
                  <a
                    href={item.exampleTemplate.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      fullWidth
                      variant="soft"
                      size={3}
                      colorTheme="neutral"
                    >
                      Download Customizable Template
                    </Button>
                  </a>
                )}
              </PopupFooter>
            </DocumentPreview>
          )}
          {!isViewer && (
            <UploadSection selectedTab={activeTab === FileUploadTab.upload}>
              <PopupContent>
                {isLabel && (
                  <LabelFieldWrapper>
                    <InputField
                      variant="outline"
                      size={2}
                      placeholder="Answer Label"
                      value={answerLabel}
                      onChange={(e) => setAnswerLabel(e.target.value)}
                    />
                  </LabelFieldWrapper>
                )}
                <FileField onSelect={setSelected} multiple />
                {selected.length > 0 && (
                  <>
                    <Text variant="semibold-4">Selected files</Text>
                    <FileList>
                      {selected.map((file, index) => (
                        <FileItem key={index}>
                          <FileName variant="body-m-regular">
                            {file.name}
                          </FileName>
                        </FileItem>
                      ))}
                    </FileList>
                  </>
                )}
                {uploaded?.length > 0 && (
                  <>
                    <Text variant="semibold-4">Uploaded files</Text>
                    <FileList>
                      {uploaded.map((file, index) => (
                        <FileItem key={index}>
                          <FileName variant="regular-4">
                            <a
                              href={file.path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {file.name}
                            </a>
                          </FileName>
                          <IconLink
                            size={1}
                            colorTheme="neutral"
                            name="trashCan2"
                            type="Outline"
                            onClick={() => handleRemove(index)}
                          />
                        </FileItem>
                      ))}
                    </FileList>
                  </>
                )}
              </PopupContent>
              <PopupFooter>
                <Button
                  fullWidth
                  variant="solid"
                  colorTheme="neutral"
                  size={3}
                  onClick={handleSubmit}
                  disabled={
                    (selected.length === 0 && uploaded.length === 0) ||
                    status === 'posting'
                  }
                  isLoading={status === 'posting'}
                >
                  Submit
                </Button>
              </PopupFooter>
            </UploadSection>
          )}
        </PopupGrid>
      </PopupGridWrapper>
    </PopupContainer>
  )
}
