import styled from 'styled-components'
import { Flex } from '@/components/ui'

export const ProposalCardWrapper = styled(Flex)`
  border: 1px solid var(--neutral-alpha-4);
  border-radius: 12px;
  background-color: var(--background-reverse-1);
  &:hover {
    background-color: var(--background-reverse-2);
  }
  padding: 12px;
  gap: 8px;
  flex-direction: column;
  cursor: pointer;
`
