import React, { InputHTMLAttributes, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'
import { Text, textVariants } from './Text'
import { Flex } from './Flex'

const Container = styled.label<{ isHover: boolean }>`
  background: var(--neutral-alpha-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 178px;
  border: 1px dashed var(--neutral-alpha-7);
  border-radius: 16px;
  color: var(--neutral-11);
  cursor: pointer;

  .title {
    ${(p) =>
      p.isHover &&
      css`
        color: var(--primary-alpha-11);
        border-bottom: transparent;
      `}
  }
  ${(p) =>
    p.isHover &&
    css`
      background: var(--primary-alpha-2);
      border: 2px solid var(--primary-alpha-9);
    `}

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    left: -100;
    top: -100;
  }

  i {
    margin-bottom: 16px;
  }

  > :last-child {
    color: var(--grey-500);
  }
`

const CTABox = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
`

const Title = styled(Flex)`
  height: 20px;
  ${textVariants['semibold-3']}
  color: var(--neutral-12);
  border-bottom: 1px solid var(--neutral-12);
  flex-shrink: 0;
`

const Subtitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
`

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect'> {
  onSelect: (files: File[]) => void
  title?: string
  subtitle?: string
}

export const FileField = ({
  title = 'Click to upload',
  subtitle = 'or drag and drop',
  onSelect,
  className,
  ...rest
}: Props) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return
    onSelect(Array.from(e.target.files))
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    setIsHovering(true)
  }

  const handleDrop = (e: React.DragEvent) => {
    setIsHovering(false)
    e.preventDefault()

    if (e.dataTransfer.items) {
      const items = Array.from(e.dataTransfer.items)
      items.some((item) => {
        const file = item.getAsFile()
        if (file) {
          onSelect([file])
          return true
        }
      })
    } else {
      const files = Array.from(e.dataTransfer.files)
      if (files.length > 0) {
        onSelect(files)
      }
    }
  }

  return (
    <Container
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={() => setIsHovering(false)}
      className={className}
      isHover={isHovering}
    >
      <Icon.Outline name="arrowOutOfBox" size={32} />
      <CTABox>
        <Title className="title">{title}</Title>
        <Subtitle variant="body-s-regular">{subtitle}</Subtitle>
      </CTABox>

      <input type="file" onChange={handleFile} {...rest} />
    </Container>
  )
}
