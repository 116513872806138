import styled from 'styled-components'

type AvatarStatusTheme = 'neutral' | 'success' | 'danger'

type BaseAvatarStatusProps = {
  size: 1 | 2 | 3 | 4 | 5 | 6
  colorTheme: AvatarStatusTheme
}

const sizeMap = {
  1: 6,
  2: 8,
  3: 10,
  4: 12,
  5: 14,
  6: 16,
}

const colorMap: Record<AvatarStatusTheme, string> = {
  neutral: 'var(--neutral-7)',
  success: 'var(--success-9)',
  danger: 'var(--danger-9)',
}

const BaseAvatarStatus = styled.div<BaseAvatarStatusProps>`
  width: ${(p) => sizeMap[p.size]}px;
  height: ${(p) => sizeMap[p.size]}px;
  border-radius: 999px;
  border: 2px solid var(--base-white);
  background-color: ${(p) => colorMap[p.colorTheme]};
`

export default BaseAvatarStatus
