import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import noop from 'lodash/noop'
import { Popup } from '@/components/uiJar/popup'
import { PaymentPopup } from '@/components/ui'
import { WizardPaymentIntent } from '@/utils/Api/payments.service'
import { PaymentReason } from '@/constants/payments'
import { useWizardContext } from '@/contexts/Wizard'
import { IProposal } from '@/models/IProposal'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import useApi from '@/hooks/useApi'
import { ViewProposalDetail } from '../Popups/ViewProposals/ViewProposalDetail'
import { CongratulationsPopup } from '../Popups'
import ViewAllProposals from '../Popups/ViewProposals/ViewAllProposals'

export const ViewProposalFlow = forwardRef<{ trigger: () => void }>(
  ({}, ref) => {
    const { id } = useWizardContext()
    const { api, status } = useApi()
    const [isTriggered, setTriggered] = useState(false)
    const [step, setStep] = useState(0)
    const [paymentId, setPaymentId] = useState<string>()
    const [selectedProposal, setSelectedProposal] = useState<IProposal | null>()
    const [proposals, setProposals] = useState<IProposal[]>([])

    useEffect(() => {
      if (!id) return
      api.wizard
        .getProposals(id as string)
        .then((data) => {
          setProposals(data)
        })
        .catch(noop)
    }, [id, api.wizard])

    const handleClose = () => {
      setTriggered(false)
      setStep(0)
    }

    const onProposalSelect = (selectedProposal?: IProposal) => {
      setSelectedProposal(selectedProposal)
      setStep(1)
    }

    const onGoBack = () => {
      setStep(0)
    }

    const returnUrl = `${
      new URL(location.pathname, location.href).href
    }?flow=payment-${paymentId}`

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    if (!isTriggered) return null

    return (
      <>
        <Popup fullScreenInMobile isOpen={step === 0}>
          <ViewAllProposals
            onProposalSelect={onProposalSelect}
            onClose={handleClose}
            proposals={proposals}
          />
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 1}>
          <ViewProposalDetail
            onClose={handleClose}
            proposal={selectedProposal}
            onPayment={(data: IUserVisaWizardPayment) => {
              setPaymentId(data.id)
              setStep(2)
            }}
            onGoBack={onGoBack}
          />
        </Popup>
        <Popup isOpen={step === 2}>
          {paymentId && (
            <PaymentPopup<WizardPaymentIntent>
              type={PaymentReason.WizardPayment}
              wizardPaymentId={paymentId}
              returnUrl={returnUrl}
              onSuccess={() => {
                setStep(4)
              }}
              onClose={() => {
                setPaymentId(undefined)
                setTriggered(false)
                setStep(0)
              }}
            />
          )}
        </Popup>

        <Popup isOpen={step === 3}>
          <CongratulationsPopup
            title={`Congratulations`}
            message="Your payment has been received"
            onClose={() => {
              window.location.href = `${
                new URL(location.pathname, location.href).href
              }`
            }}
          />
        </Popup>
      </>
    )
  },
)

ViewProposalFlow.displayName = 'ViewProposalFlow'
