import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import Pusher from 'pusher-js'
import useApi from '@/hooks/useApi'
import useInboxV2Chat from '@/hooks/userInboxV2Chat'
import { useAuthContext } from '../Auth'
import { useMessagesContext } from '../Inbox'

interface PusherContextProps {
  pusher?: Pusher
}

export const PusherContext = createContext<PusherContextProps>({})

export const PusherProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuthContext()
  const { api } = useApi()
  const messagesContext = useMessagesContext()
  const { deleteChat } = useInboxV2Chat()
  const pusherRef = useRef<Pusher | undefined>(undefined)
  const onEvent = useCallback(
    (eventName: string, data: any) => {
      console.log('Pusher Event Triggered:', { eventName, data })

      switch (eventName) {
        case 'new-message':
          messagesContext.onNewMessage(data)
          break
        case 'delete-message':
          messagesContext.onMessageDelete(data)
          break
        case 'update-message':
          messagesContext.onMessageUpdate(data)
          break
        case 'chat-update':
          messagesContext.onChatUpdate(data.id, data)
          break
        case 'chat-request-update':
          messagesContext.onChatUpdate(data.id, data)
          break
        case 'chat-new':
          messagesContext.onNewChat(data)
          break
        case 'chat-request-new':
          messagesContext.onNewChat(data)
          break
        case 'chat-requests-update-list-item':
          messagesContext.onChatRequest(data)
          break
        case 'chat-deleted':
          deleteChat(data.id)
          messagesContext.removeChat(data.id)
          break
        default:
          break
      }
    },
    [messagesContext],
  )

  useEffect(() => {
    if (!user?.id) return

    // Initialize Pusher only if it doesn't already exist
    if (!pusherRef.current) {
      pusherRef.current = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY || '', {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: '/pusher/auth',
          transport: 'ajax',
          customHandler: async ({ socketId }, callback) => {
            try {
              const response = await api.utils.authorizePusher(socketId)
              callback(null, response)
            } catch (error: any) {
              console.error('Pusher authorization failed:', error)
              callback(error, null)
            }
          },
        },
      })
      console.log('Pusher instance initialized.')
    }

    const pusher = pusherRef.current
    const channelName = `private-user-${user.id}`
    const channel = pusher.subscribe(channelName)

    console.log('Pusher connecting to channel:', channelName)

    channel.bind_global(onEvent)
    channel.bind('pusher:subscription_succeeded', () =>
      console.log('Pusher connected to channel:', channelName),
    )
    channel.bind('pusher:subscription_error', (error: any) =>
      console.error('Pusher subscription error:', error),
    )

    pusher.connection.bind('connected', () =>
      console.log('Pusher successfully connected.'),
    )
    pusher.connection.bind('disconnected', () =>
      console.log('Pusher disconnected.'),
    )

    pusher.connect()

    return () => {
      console.log('Pusher disconnecting from channel:', channelName)
      pusher.unsubscribe(channelName)
      pusher.unbind_all()
      pusher.disconnect()
      console.log('Pusher instance disconnected and cleaned up.')
      pusherRef.current = undefined // Clear the Pusher instance
    }
  }, [user?.id, api.utils, onEvent])

  return (
    <PusherContext.Provider value={{ pusher: pusherRef.current }}>
      {children}
    </PusherContext.Provider>
  )
}

export const usePusherContext = () => useContext(PusherContext)
