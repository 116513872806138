import React from 'react'
import times from 'lodash/times'
import { PopupTopBar } from '@/components/uiJar/popup'
import {
  DefaultPopupFooter,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'
import { Flex } from '@/components/ui'
import { Button } from '@/components/uiJar/Button'
import { IProposal } from '@/models/IProposal'
import ProposalCard from './ProposalCard'

type ViewAllProposalsProps = {
  onClose: () => void
  onProposalSelect: (proposal?: IProposal) => void
  proposals: IProposal[]
}

const ViewAllProposals: React.FC<ViewAllProposalsProps> = ({
  onClose,
  onProposalSelect,
  proposals,
}) => {
  return (
    <FullScreenMobileContainer>
      <PopupTopBar onClose={onClose}>View proposal</PopupTopBar>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={16}>
          {proposals.map((proposal, index) => (
            <ProposalCard
              index={proposals.length - index}
              key={proposal.id}
              proposal={proposal}
              onSelect={() => onProposalSelect(proposal)}
            />
          ))}
        </Flex>
      </FullScreenPopupContentWrapper>
      <DefaultPopupFooter>
        <Button
          onClick={onClose}
          fullWidth
          size={3}
          variant="solid"
          colorTheme="neutral"
        >
          Close
        </Button>
      </DefaultPopupFooter>
    </FullScreenMobileContainer>
  )
}

export default ViewAllProposals
