import React from 'react'
import {
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  OptionProps,
  StylesConfig,
  components,
  MenuListProps,
  ClearIndicatorProps,
  MultiValueProps,
} from 'react-select'
import styled from 'styled-components'
import _ from 'lodash'
import { Skeleton } from '@/components/ui/Skeleton'
import { textVariants } from '@/components/ui/Text'
import { ArrowIconWrapper, DropdownIndicator } from './styles'
import { Icon } from '../../ui/Icon'
import { SelectInputSize } from '.'

export function getSelectThemeColors(currentTheme: 'light' | 'dark' = 'light') {
  const lightThemeColors = {
    'neutral-alpha-12': '#000509e3',
    'neutral-alpha-11': '#0007139f',
    'panel-1': '#ffffff',
    'panel-3': '#f9f9fb',
    'neutral-alpha-6': '#01012e22',
    'neutral-alpha-7': '#00063332',
    'neutral-12': 'rgb(31, 35, 39)',
    'neutral-alpha-4': '#02023414',
    'neutral-alpha-3': '#00003b0d',
    'neutral-alpha-5': '#0000301b',
    'danger-9': 'rgb(229, 72, 77)',
    'danger-alpha-4': '#ff00001a',
  }

  const darkThemeColors = {
    'neutral-alpha-12': '#fcfcfcf0',
    'neutral-alpha-11': '#fcfcfcb2',
    'panel-1': '#000000',
    'panel-3': '#0f0f0f',
    'neutral-alpha-6': '#f2f2f22b',
    'neutral-alpha-7': '#00063332',
    'neutral-12': 'rgb(238, 238, 238)',
    'neutral-alpha-4': '#ffffff1c',
    'neutral-alpha-3': '#e5e5e51a',
    'neutral-alpha-5': '#ffffff21',
    'danger-9': 'rgb(229, 72, 77)',
    'danger-alpha-4': '#f8596d4a',
  }

  if (currentTheme === 'dark') return darkThemeColors
  return lightThemeColors
}

const fontSizeCss: Record<
  SelectInputSize,
  { fontSize: string; lineHeight: string }
> = {
  1: { fontSize: '14px', lineHeight: '20px' },
  2: { fontSize: '14px', lineHeight: '20px' },
  3: { fontSize: '16px', lineHeight: '24px' },
  4: { fontSize: '16px', lineHeight: '24px' },
}

const paddingCss: Record<SelectInputSize, string> = {
  1: '0px 4px 0px 8px',
  2: '0px 8px 0px 10px',
  3: '0px 12px 0px 12px',
  4: '0px 16px 0px 16px',
}

const paddingCssWithStartIcon: Record<SelectInputSize, string> = {
  1: '6px 4px 6px 32px',
  2: '10px 8px 10px 36px',
  3: '12px 12px 12px 44px',
  4: '16px 16px 16px 48px',
}

const heightCss: Record<SelectInputSize, string> = {
  1: '32px',
  2: '40px',
  3: '48px',
  4: '56px',
}

const borderRadiusCss: Record<SelectInputSize, string> = {
  1: '6px',
  2: '8px',
  3: '10px',
  4: '12px',
}

const menuItemPaddingCss: Record<SelectInputSize, string> = {
  1: '6px 8px',
  2: '8px 8px',
  3: '8px 8px',
  4: '8px 8px',
}

const menuItemHeightCss: Record<SelectInputSize, string> = {
  1: '32px',
  2: '40px',
  3: '40px',
  4: '40px',
}

const menuItemBorderRadiusCss: Record<SelectInputSize, string> = {
  1: '6px',
  2: '8px',
  3: '8px',
  4: '8px',
}

const tagHeightCss: Record<SelectInputSize, string> = {
  1: '20px',
  2: '20px',
  3: '24px',
  4: '32px',
}

const tagBorderRadiusCss: Record<SelectInputSize, string> = {
  1: '4px',
  2: '4px',
  3: '6px',
  4: '8px',
}

const tagPaddingCss: Record<SelectInputSize, string> = {
  1: '2px 6px',
  2: '4px 8px',
  3: '4px 8px',
  4: '6px 12px',
}

const tagFontSizeCss: Record<
  SelectInputSize,
  { fontSize: string; lineHeight: string }
> = {
  1: { fontSize: '12px', lineHeight: '16px' },
  2: { fontSize: '12px', lineHeight: '16px' },
  3: { fontSize: '12px', lineHeight: '16px' },
  4: { fontSize: '14px', lineHeight: '20px' },
}
export default function getSelectStyles(
  hasError = false,
  size: SelectInputSize,
  hasStartIcon = false,
): StylesConfig {
  const colors = getSelectThemeColors()

  return {
    singleValue: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        ...fontSizeCss[size],
        color: colors['neutral-alpha-12'],
        fontWeight: 500,
      } as CSSObjectWithLabel),
    valueContainer: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        padding: '0px',
      } as CSSObjectWithLabel),
    placeholder: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        ...fontSizeCss[size],
        padding: '0px',
        color: colors['neutral-alpha-11'],
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      } as CSSObjectWithLabel),
    control: (
      baseStyles: CSSObjectWithLabel,
      { isFocused }: ControlProps,
    ): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        minHeight: heightCss[size],
        borderRadius: borderRadiusCss[size],
        background: colors['panel-1'],
        '&:hover': {
          background: isFocused ? colors['panel-1'] : colors['panel-3'],
        },
        padding: hasStartIcon
          ? paddingCssWithStartIcon[size]
          : paddingCss[size],
        borderColor: hasError
          ? colors['danger-9']
          : isFocused
          ? colors['neutral-12']
          : colors['neutral-alpha-6'],
        boxShadow: hasError
          ? `0px 0px 0px 2px ${colors['danger-alpha-4']}`
          : isFocused
          ? `0px 0px 0px 2px ${colors['neutral-alpha-4']}`
          : 'none',
      } as CSSObjectWithLabel),
    option: (
      baseStyles: CSSObjectWithLabel,
      { isSelected }: OptionProps,
    ): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        ...fontSizeCss[size],
        padding: menuItemPaddingCss[size],
        height: menuItemHeightCss[size],
        borderRadius: menuItemBorderRadiusCss[size],
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        color: colors['neutral-12'],
        background: isSelected ? colors['neutral-alpha-3'] : 'none',
        '&:hover': {
          background: colors['neutral-alpha-3'],
        },
      } as CSSObjectWithLabel),

    menu: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        zIndex: 3,
        background: colors['panel-1'],
        padding: '8px 8px 4px 8px',
        marginBottom: '8px',
        border: `1px solid ${colors['neutral-alpha-4']}`,
        borderRadius: '16px',
        boxShadow: '0px 12px 60px 0px #0000000A, 0px 12px 32px -16px #0000001A',
      } as CSSObjectWithLabel),
    menuList: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel =>
      ({
        ...baseStyles,
        padding: '0px',
        //custom scrollbar
        '&::-webkit-scrollbar': {
          width: '4px',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: colors['neutral-alpha-3'],
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: colors['neutral-alpha-6'],
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: colors['neutral-alpha-7'],
        },
      } as CSSObjectWithLabel),
    multiValue: (base: CSSObjectWithLabel) =>
      ({
        ...base,
        backgroundColor: 'transparent',
        border: `1px solid ${colors['neutral-alpha-6']}`,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        borderRadius: tagBorderRadiusCss[size],
        height: tagHeightCss[size],
        padding: tagPaddingCss[size],
        '&:hover': {
          backgroundColor: colors['neutral-alpha-3'],
        },
      } as CSSObjectWithLabel),
    multiValueLabel: (base: CSSObjectWithLabel) =>
      ({
        ...base,
        padding: 0,
        paddingLeft: 0,
        fontSize: tagFontSizeCss[size].fontSize,
        lineHeight: tagFontSizeCss[size].lineHeight,
      } as CSSObjectWithLabel),

    multiValueRemove: (base: CSSObjectWithLabel) =>
      ({
        ...base,
        color: colors['neutral-12'],
        padding: '0px 0px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      } as CSSObjectWithLabel),
  }
}

const SkeletonItem = styled(Skeleton)<{
  size: SelectInputSize
}>`
  border-radius: ${(p) => borderRadiusCss[p.size]};
  height: ${(p) => menuItemHeightCss[p.size]};
`

export const CustomMenuList = (props: MenuListProps) => {
  const { children, selectProps, ...rest } = props

  return (
    <components.MenuList selectProps={selectProps} {...rest}>
      {children}

      {selectProps.isMenuLoading && (
        <>
          {_.times(3, (i) => (
            <SkeletonItem size={selectProps.size} key={i} />
          ))}
        </>
      )}
    </components.MenuList>
  )
}

export const CustomDropdownIndicator = (data: DropdownIndicatorProps) => {
  const colors = getSelectThemeColors()
  return (
    <DropdownIndicator
      style={{
        borderRadius: '4px',
        background:
          data.isFocused || data.selectProps.hovered
            ? colors['neutral-alpha-3']
            : 'none',
        color: colors['neutral-12'],
      }}
    >
      {data.isFocused ? (
        <ArrowIconWrapper>
          <Icon.Outline name="chevronTopSmall" size={16} />
        </ArrowIconWrapper>
      ) : (
        <ArrowIconWrapper>
          <Icon.Outline name="chevronDownSmall" size={16} />
        </ArrowIconWrapper>
      )}
    </DropdownIndicator>
  )
}

export const CustomClearIndicator = (props: ClearIndicatorProps) => {
  const { children, isFocused, selectProps, ...rest } = props
  const colors = getSelectThemeColors()
  return (
    <components.ClearIndicator
      selectProps={selectProps}
      isFocused={isFocused}
      {...rest}
    >
      <DropdownIndicator
        style={{
          borderRadius: '4px',

          background:
            isFocused || selectProps.hovered
              ? colors['neutral-alpha-3']
              : 'none',
          color: colors['neutral-12'],
        }}
      >
        <ArrowIconWrapper {...rest}>
          <Icon.Solid name="xMarkSmall" size={16} />
        </ArrowIconWrapper>
      </DropdownIndicator>
    </components.ClearIndicator>
  )
}

export const CustomMultiValueRemove = (props: MultiValueProps) => {
  const { ...rest } = props
  return (
    <components.MultiValueRemove {...rest}>
      <Icon.Solid size={16} name="xMarkSmall" />
    </components.MultiValueRemove>
  )
}

export const CustomIndicatorSeparator = () => null
