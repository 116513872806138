import styled from 'styled-components'
import { Flex } from '@/components/ui'
import { DefaultPopupFooter } from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const KeyIcon = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--success-alpha-3);
  justify-content: center;
  align-items: center;
  i {
    color: var(--success-11);
  }
`

export const FeaturesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  background-color: var(--success-3);
`

export const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  li {
    display: flex;
    gap: 6px;
    i {
      color: var(--success-9);
    }
  }
`
export const Logos = styled(Flex)`
  align-items: center;
  gap: 11px;
  margin-bottom: 32px;
`

export const PopupFooter = styled(DefaultPopupFooter)`
  border-top: transparent;
  .cancel {
    display: none;
  }
  a {
    width: 100%;
  }
  ${Media.Tablet`
    border-top: 1px solid var(--neutral-alpha-4);
    .cancel {
      display: block;
    }
    `}
`
