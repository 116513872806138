import React, { HTMLAttributes, ElementType } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { textVariants } from '../../ui/Text'
import {
  FlagIcon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '../../ui/Icon'
import IconRenderer from '../helper/IconRenderer'

type BadgeVariant = 'solid' | 'soft' | 'outline'
type BadgeColorTheme =
  | 'primary'
  | 'accent'
  | 'neutral'
  | 'danger'
  | 'success'
  | 'warning'
type BadgeSize = 1 | 2 | 3

interface IconProps {
  type: IconType
  name?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}

export interface BadgeProps extends HTMLAttributes<HTMLElement> {
  variant: BadgeVariant
  colorTheme: BadgeColorTheme
  size: BadgeSize
  startIcon?: React.ReactNode
  as?: ElementType
  icon?: IconProps
  fullWidth?: boolean
  iconBadge?: boolean
  highContrast?: boolean
}

const getSolidVariants = (highContrast = false) => ({
  primary: css`
    background-color: var(--primary-9);
    color: var(--panel-1);
    ${highContrast && 'background-color: var(--primary-12)'}
  `,
  accent: css`
    background-color: var(--accent-9);
    color: var(--panel-1);
    ${highContrast && 'background-color: var(--accent-12)'}
  `,
  neutral: css`
    background-color: var(--neutral-9);
    color: var(--panel-1);
    ${highContrast && 'background-color: var(--neutral-12)'}
  `,
  danger: css`
    background-color: var(--danger-9);
    color: var(--panel-1);
    ${highContrast && 'background-color: var(--danger-12)'}
  `,
  success: css`
    background-color: var(--success-9);
    color: var(--panel-1);
    ${highContrast && 'background-color: var(--success-12)'}
  `,
  warning: css`
    background-color: var(--warning-9);
    color: var(--base-black);
    ${highContrast && 'background-color: var(--warning-12)'}
  `,
})

const getSoftVariants = (highContrast = false) => ({
  primary: css`
    background-color: var(--primary-alpha-3);
    color: var(--primary-alpha-11);
    ${highContrast && 'background-color: var(--primary-alpha-12)'}
  `,
  accent: css`
    background-color: var(--accent-alpha-3);
    color: var(--accent-alpha-11);
    ${highContrast && 'background-color: var(--accent-alpha-12)'}
  `,
  neutral: css`
    background-color: var(--neutral-alpha-3);
    color: var(--neutral-alpha-11);
    ${highContrast && 'background-color: var(--neutral-alpha-12)'}
  `,
  danger: css`
    background-color: var(--danger-alpha-3);
    color: var(--danger-alpha-11);
    ${highContrast && 'background-color: var(--danger-alpha-12)'}
  `,
  success: css`
    background-color: var(--success-alpha-3);
    color: var(--success-alpha-11);
    ${highContrast && 'background-color: var(--success-alpha-12)'}
  `,
  warning: css`
    background-color: var(--warning-alpha-3);
    color: var(--warning-alpha-11);
    ${highContrast && 'background-color: var(--warning-alpha-12)'}
  `,
})

const getOutlineVariants = (highContrast = false) => ({
  primary: css`
    border-color: var(--primary-alpha-8);
    color: var(--primary-alpha-11);
    ${highContrast && 'border-color: var(--primary-alpha-11)'}
    ${highContrast && 'color: var(--primary-alpha-12)'}
  `,
  accent: css`
    border-color: var(--accent-alpha-8);
    color: var(--accent-alpha-11);
    ${highContrast && 'border-color: var(--accent-alpha-11)'}
    ${highContrast && 'color: var(--accent-alpha-12)'}
  `,
  neutral: css`
    border-color: var(--neutral-alpha-8);
    color: var(--neutral-alpha-11);
    ${highContrast && 'border-color: var(--neutral-alpha-11)'}
    ${highContrast && 'color: var(--neutral-alpha-12)'}
  `,
  danger: css`
    border-color: var(--danger-alpha-8);
    color: var(--danger-alpha-11);
    ${highContrast && 'border-color: var(--danger-alpha-11)'}
    ${highContrast && 'color: var(--danger-alpha-12)'}
  `,
  success: css`
    border-color: var(--success-alpha-8);
    color: var(--success-alpha-11);
    ${highContrast && 'border-color: var(--success-alpha-11)'}
    ${highContrast && 'color: var(--success-alpha-12)'}
  `,
  warning: css`
    border-color: var(--warning-alpha-8);
    color: var(--warning-alpha-11);
    ${highContrast && 'border-color: var(--warning-alpha-11)'}
    ${highContrast && 'color: var(--warning-alpha-12)'}
  `,
})

const getVariantsMap = (highContrast = false) => ({
  solid: getSolidVariants(highContrast),
  soft: getSoftVariants(highContrast),
  outline: getOutlineVariants(highContrast),
})

const getSizeCss = (iconBadge?: boolean) => ({
  1: css`
    ${textVariants['medium-2']}
    height: 20px;
    ${!iconBadge && 'padding: 0px 6px;'}
    ${iconBadge && 'width: 20px;'}
    border-radius: 9999px;
    gap: 2px;
  `,
  2: css`
    ${textVariants['medium-2']}
    height: 24px;
    ${!iconBadge && 'padding: 0px 8px;'}
    ${iconBadge && 'width: 24px;'}
    border-radius: 9999px;
    gap: 4px;
  `,
  3: css`
    ${textVariants['medium-3']}
    height: 32px;
    ${!iconBadge && 'padding: 0px 12px;'}
    ${iconBadge && 'width: 32px;'}
    border-radius: 9999px;
    gap: 4px;
  `,
})

const iconSize: Record<BadgeSize, number> = {
  1: 12,
  2: 16,
  3: 18,
}

const StyledBadge = styled.div<BadgeProps>`
  font-family: var(--font-inter);
  cursor: default;
  display: flex;
  width: ${(p) => (p.fullWidth ? '100%' : 'fit-content')};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.3s ease-out all;
  border: 1px solid transparent;
  padding: 0;
  outline: none;
  background-color: transparent;
  ${(p) => getSizeCss(p.iconBadge)[p.size]}
  ${(p) => getVariantsMap(p.highContrast)[p.variant][p.colorTheme]}
`

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      variant,
      size,
      colorTheme,
      fullWidth,
      children,
      icon,
      iconBadge,
      highContrast,
      ...rest
    },
    ref,
  ) => {
    iconBadge = iconBadge || !children
    return (
      <StyledBadge
        ref={ref}
        variant={variant}
        size={size}
        colorTheme={colorTheme}
        fullWidth={fullWidth}
        iconBadge={iconBadge}
        highContrast={highContrast}
        {...rest}
      >
        <IconRenderer
          sizeMap={iconSize}
          size={size}
          iconType={icon?.type}
          iconName={icon?.name}
        />
        {children}
      </StyledBadge>
    )
  },
)

Badge.displayName = 'Badge'
