import React, { useMemo, useState } from 'react'
import { Popup } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { Badge } from '@/components/uiJar/badge'
import { PremiumUpgradeReason } from '@/contexts/PremiumSubscription/types'
import { YEARLY_SUBSCRIPTION_PRICE } from '@/contexts/PremiumSubscription/constants'
import { useTheme } from '@/contexts/Theme'
import { showToast } from '@/utils/toast'
import {
  PopupContainer,
  PopupContent,
  Hero,
  HeroImage,
  TagLine,
  Body,
  CloseWrapper,
  Footer,
  PromoWrapper,
  PromoBox,
} from './styles'

import { ITEMS } from './constants'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { imageUrl } from '../Image'

export enum UpgradeVariant {
  DEFAULT = 'default',
  EARLY_ADOPTER = 'early-adopter',
}

type UpgradePopupProps = {
  opened: boolean
  onClose: () => void
  onProceed: () => void
  reason?: PremiumUpgradeReason
  variant?: UpgradeVariant
}

const EARLY_ADOPTER_PROMO = 'earlyadopter'

export const UpgradePopup = ({
  onProceed,
  opened,
  onClose,
  reason,
  variant = UpgradeVariant.DEFAULT,
}: UpgradePopupProps) => {
  const { theme } = useTheme()
  const isDark = theme === 'dark'

  const handleCopyPromo = () => {
    navigator.clipboard
      .writeText(EARLY_ADOPTER_PROMO)
      .then(() => {
        showToast({ type: 'success', message: 'Promo code copied!' })
      })
      .catch(() => {
        showToast({
          type: 'error',
          message: 'Failed to copy promo code. Please copy it manually',
        })
      })
  }

  const illustrationUrl = useMemo(() => {
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return imageUrl('/img/premium/citizen-remote.svg')
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return imageUrl('/img/premium/social.svg')
      default:
        return imageUrl('/img/premium/citizen-remote.svg')
    }
  }, [reason])

  const title = useMemo(() => {
    if (variant === UpgradeVariant.EARLY_ADOPTER) {
      return 'Get Premium with 90% off!'
    }
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return 'Citizen Remote Premium'
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return 'Get the full Social experience'
      default:
        return 'Citizen Remote Premium'
    }
  }, [reason, variant])

  const DescriptionRenderer = () => {
    if (variant === UpgradeVariant.EARLY_ADOPTER) {
      return (
        <Text variant="regular-4" colorVar="neutral-11">
          Get Citizen Remote Premium for{' '}
          <span className="old-price">$99.99/year</span>{' '}
          <span className="new-price">$10/year!</span> Lock your early bird
          price with promo code:
        </Text>
      )
    }

    return (
      <Text variant="regular-4" colorVar="neutral-11">
        Go beyond the limits and unlock dozens of exclusive features by
        subscribing to Citizen Remote Premium.
      </Text>
    )
  }

  const PromoRenderer = () => {
    if (variant === UpgradeVariant.EARLY_ADOPTER) {
      return (
        <PromoWrapper>
          <PromoBox onClick={handleCopyPromo}>
            <Text variant="regular-6">earlyadopter</Text>
            <Icon.Outline name="squareBehindSquare" size={24} />
          </PromoBox>
          <TagLine noPadding>
            Use promo code during checkout to get the deal.
          </TagLine>
        </PromoWrapper>
      )
    }
    return (
      <TagLine>
        Get Citizen Remote Premium for just ${YEARLY_SUBSCRIPTION_PRICE}
        /year!
      </TagLine>
    )
  }

  return (
    <Popup fullScreenInMobile isOpen={opened}>
      <PopupContainer>
        <CloseWrapper justifyContent="flex-end">
          <Button
            icon={{ startIconName: 'crossSmall', type: 'Solid' }}
            size={2}
            variant="ghost"
            colorTheme="neutral"
            onClick={onClose}
          />
        </CloseWrapper>

        <PopupContent>
          <Hero>
            <HeroImage
              alt={`Premium subscription illustration for ${title}`}
              src={illustrationUrl}
            />
            <Flex direction="column" gap={4}>
              <Text variant="semibold-6">{title}</Text>
              <DescriptionRenderer />
            </Flex>
          </Hero>
          <PromoRenderer />
          <Body>
            <Text variant="medium-3" colorVar="neutral-11">
              What’s included in Premium?
            </Text>
            <Flex direction="column" gap={24} as="ul">
              {ITEMS.map((item) => (
                <Flex key={item.title} gap={8} as="li">
                  <Icon.Base name={item.icon} size={20} />
                  <Text
                    variant="medium-3"
                    colorVar={item.comingSoon ? 'neutral-11' : 'neutral-12'}
                  >
                    {item.title}
                  </Text>
                  {item.new && (
                    <Badge size={1} variant="soft" colorTheme="accent">
                      new
                    </Badge>
                  )}
                  {item.comingSoon && (
                    <Badge size={1} variant="soft" colorTheme="neutral">
                      coming soon
                    </Badge>
                  )}
                </Flex>
              ))}
            </Flex>
          </Body>
        </PopupContent>
        <Footer $isDark={isDark}>
          <Button
            fullWidth
            variant="solid"
            colorTheme="neutral"
            size={3}
            onClick={onProceed}
          >
            Subscribe for ${YEARLY_SUBSCRIPTION_PRICE}/year
          </Button>
          <Flex direction="column">
            <Text variant="medium-2" colorVar="neutral-9">
              By subscribing to premium, you agree to our
            </Text>
            <Text variant="medium-2" colorVar="neutral-9">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://citizenremote.com/terms-of-use/"
              >
                Terms of Service
              </a>{' '}
              and accept our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://citizenremote.com/eula/'}
              >
                EULA
              </a>
            </Text>
          </Flex>
        </Footer>
      </PopupContainer>
    </Popup>
  )
}
