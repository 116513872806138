import React from 'react'
import Link from 'next/link'
import { PopupTopBar } from '@/components/uiJar/popup'
import {
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
  MobileOnlyContainer,
} from '@/components/uiJar/utilityStyles'
import { Flex, Icon, Text } from '@/components/ui'
import { Button } from '@/components/uiJar/Button'
import {
  FeaturesList,
  FeaturesListWrapper,
  KeyIcon,
  Logos,
  PopupFooter,
} from './styles'
import { features } from './constant'

type InsurancePopupProps = {
  onClose: () => void
}

const InsurancePopup: React.FC<InsurancePopupProps> = ({ onClose }) => {
  return (
    <FullScreenMobileContainer>
      <MobileOnlyContainer>
        <PopupTopBar noBorder onClose={onClose}>
          Insurance
        </PopupTopBar>
      </MobileOnlyContainer>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={20}>
          <KeyIcon>
            <Icon.Solid name="shieldCheck" size={20} />
          </KeyIcon>
          <Flex direction="column" gap={4}>
            <Text variant="semibold-6">Your safety net abroad</Text>
            <Text variant="regular-3" colorVar="neutral-11">
              Let’s Prepare For The Unexpected With Travel Insurance!
            </Text>
          </Flex>
          <FeaturesListWrapper>
            <Text variant="medium-2" colorVar="neutral-alpha-11">
              What you’ll get
            </Text>
            <FeaturesList>
              {features.map((feature, index) => (
                <li key={index}>
                  <Icon.Solid name="checkCircle" size={16} />
                  <Text variant="regular-3">{feature}</Text>
                </li>
              ))}
            </FeaturesList>
          </FeaturesListWrapper>
          <Logos>
            <Icon.Base name="worldTrips" width={96} />
            <Text variant="regular-3">+</Text>
            <Icon.Base name="LogoFullBlack" width={123} />
          </Logos>
        </Flex>
      </FullScreenPopupContentWrapper>
      <PopupFooter>
        <Button
          onClick={onClose}
          fullWidth
          size={3}
          variant="outline"
          colorTheme="neutral"
          className="cancel"
        >
          Cancel
        </Button>
        <Link passHref href={'/insurance/form'}>
          <Button fullWidth size={3} variant="solid" colorTheme="neutral">
            Get travel insurance
          </Button>
        </Link>
      </PopupFooter>
    </FullScreenMobileContainer>
  )
}

export default InsurancePopup
