import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useAuthContext } from '@/contexts/Auth'
import { Popup } from '@/components/uiJar/popup'
import InsurancePopup from '../Popups/Insurance'

export const InsuranceFlow = forwardRef<{ trigger: () => void }>((_, ref) => {
  const { user } = useAuthContext()
  const [isTriggered, setTriggered] = useState(false)

  const handleClose = () => {
    setTriggered(false)
    try {
      window.Sprintful.hidePopup()
    } catch {}
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup fullScreenInMobile isOpen>
        <InsurancePopup onClose={handleClose} />
      </Popup>
    </>
  )
})

InsuranceFlow.displayName = 'InsuranceFlow'
