import styled from 'styled-components'
import { Flex, Text, textVariants } from '@/components/ui'
import {
  DefaultPopupFooter,
  thinModalScrollbar,
} from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const TopbarWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  ${Media.Tablet`
    display: none;
    `}
`

export const PopupContent = styled(Flex)`
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  ${thinModalScrollbar}
`

export const ProceduresWrapper = styled(Flex)`
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
`

export const Procedures = styled.ol`
  ${textVariants['medium-3']}
  list-style: none;
  counter-reset: item;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  li {
    position: relative;
    counter-increment: item;
  }

  li:before {
    content: counter(item) '.';
    color: var(--neutral-9);
    position: absolute;
    left: -24px; /* Adjust this value as needed */
  }

  > li:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const PopupNotice = styled(Text)`
  color: var(--neutral-11);
  padding-bottom: 32px;
`

export const PopupActions = styled(DefaultPopupFooter)`
  border-top: none;
  ${Media.Tablet`
    border-top: 1px solid var(--neutral-alpha-4);
    `}
`
