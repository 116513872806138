import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import noop from 'lodash/noop'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import { PaymentPopup } from '@/components/ui'
import { showToast } from '@/utils/toast'
import useApi from '@/hooks/useApi'
import { InitialPaymentRequest } from '@/components/myCases/RequestPayment/initialRequest'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import { WizardPaymentIntent } from '@/utils/Api/payments.service'
import { PaymentReason } from '@/constants/payments'
import { UpdateProposalRequest } from '@/utils/Api/wizard.service'
import { IAdvisor } from '@/models/IAdvisor'
import { Popup } from '@/components/uiJar/popup'
import { CreateProposal } from '../Popups/CreateProposal'
import { ProposalSentPopup } from '../Popups/Congratulations/proposalSent'
import { IDraftProposal } from '../Popups/CreateProposal/types'
import { CongratulationsPopup } from '../Popups'
import { ProposalConfirmPopup } from '../Popups/CreateProposal/ProposalConfirm'
import { UserViewProposal } from '../Popups/CreateProposal/UserViewProposal'

export const CreateProposalFlow = forwardRef<
  { trigger: () => void },
  {
    proposal: IProposal | null | undefined
    advisorProfile: IAdvisor | null | undefined
    userWizardId?: string
    mode: 'advisor' | 'user'
    onUpdateProposal: (data: IProposal) => void
  }
>(({ proposal, advisorProfile, userWizardId, mode, onUpdateProposal }, ref) => {
  const { api, status } = useApi()

  const [isTriggered, setTriggered] = useState(false)
  const [draftProposal, setDraftProposal] = useState<IDraftProposal>()
  const [step, setStep] = useState(0)
  const [paymentId, setPaymentId] = useState<string>()
  const [openConfirm, setOpenConfirm] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  const handleInitialPayment = (data: IDraftProposal) => {
    setStep(1)
    setDraftProposal(data)
  }

  const handleCreateProposal = useCallback(
    (data: IDraftProposal) => {
      setOpenConfirm(false)
      const body = {
        userWizardId: data?.userWizardId || '',
        prices: data?.prices || [],
        summary: data?.summary,
        initialPayment: data?.initialPayment,
      }

      api.advisor.createProposal(body).then((data) => {
        setStep(3)
        showToast({
          type: 'success',
          message: 'Proposal created',
        })

        onUpdateProposal(data.proposal)
        setDraftProposal(undefined)
      })
    },
    [api.advisor, onUpdateProposal],
  )

  const handleUpdateProposal = useCallback(
    (data: IDraftProposal) => {
      setOpenConfirm(false)
      const body: UpdateProposalRequest = {
        prices: data?.prices || [],
        summary: data?.summary,
        initialPayment: data?.initialPayment || null,
        status: ProposalStatus.Updated,
      }

      proposal &&
        api.wizard
          .updateProposal(proposal?.id, body)
          .then((response) => {
            setStep(3)
            showToast({
              type: 'success',
              message: 'Proposal updated',
            })

            onUpdateProposal(response.proposal)
            setDraftProposal(undefined)
          })
          .catch(noop)
    },
    [api.wizard, onUpdateProposal, proposal],
  )

  const returnUrl = `${
    new URL(location.pathname, location.href).href
  }?flow=payment-${paymentId}`

  if (!isTriggered) return null

  return (
    <>
      <Popup fullScreenInMobile isOpen={step === 0}>
        {mode === 'advisor' ? (
          <CreateProposal
            onClose={() => {
              setTriggered(false)
              setDraftProposal(undefined)
            }}
            proposal={proposal}
            draftProposal={draftProposal}
            isLoading={status === 'posting'}
            userWizardId={userWizardId}
            onCreateProposal={(data: IDraftProposal) => {
              setOpenConfirm(true)
              setDraftProposal(data)
            }}
            platformFee={advisorProfile?.platformFee}
            onPaymentRequest={(data: IDraftProposal) => {
              handleInitialPayment(data)
            }}
          />
        ) : (
          <UserViewProposal
            onClose={() => setTriggered(false)}
            proposal={proposal}
            onUpdate={(data: IProposal) => onUpdateProposal(data)}
            onPayment={(data: IUserVisaWizardPayment) => {
              setPaymentId(data.id)
              setStep(2)
            }}
          />
        )}
      </Popup>
      <Popup isOpen={step === 1}>
        <InitialPaymentRequest
          isLoading={status === 'posting'}
          onClose={() => {
            setTriggered(false)
            setDraftProposal(undefined)
            setStep(0)
          }}
          onRequest={(body: IDraftProposal) => {
            setOpenConfirm(true)
            setDraftProposal(body)
          }}
          platformFee={advisorProfile?.platformFee}
          proposal={draftProposal}
          initialAmount={proposal?.initialPayment}
          onBack={() => setStep(0)}
        />
      </Popup>
      <Popup isOpen={step === 2}>
        {paymentId && (
          <PaymentPopup<WizardPaymentIntent>
            type={PaymentReason.WizardPayment}
            wizardPaymentId={paymentId}
            returnUrl={returnUrl}
            onSuccess={() => {
              setStep(4)
            }}
            onClose={() => {
              setPaymentId(undefined)
              setTriggered(false)
              setStep(0)
            }}
          />
        )}
      </Popup>
      <Popup isOpen={step === 3}>
        <ProposalSentPopup
          title="Congratulations"
          message={`Your proposal has been sent. Feel free to keep communicating with your client in messages!`}
          noteTitle="Initial payment request"
          note="If you requested an initial payment with your proposal, you'll be able to see the status in the Payments tab"
          onClose={() => {
            setTriggered(false)
            setStep(0)
          }}
        />
      </Popup>
      <Popup isOpen={step === 4}>
        <CongratulationsPopup
          title={`Congratulations`}
          message="Your payment has been received"
          onClose={() => {
            window.location.href = `${
              new URL(location.pathname, location.href).href
            }`
          }}
        />
      </Popup>
      <Popup isOpen={openConfirm}>
        <ProposalConfirmPopup
          onCancel={() => {
            setOpenConfirm(false)
          }}
          onConfirm={() => {
            if (draftProposal) {
              proposal
                ? handleUpdateProposal(draftProposal)
                : handleCreateProposal(draftProposal)
            }
          }}
        />
      </Popup>
    </>
  )
})

CreateProposalFlow.displayName = 'CreateProposalFlow'
