import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Text, Flex } from '@/components/ui'
import { certificationSupportedLangList } from '@/constants/languages'
import {
  DashedDivider,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'
import { PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import InputField from '@/components/uiJar/InputField'
import SelectInput from '@/components/uiJar/SelectInput'
import { FromTwoContainer, PopupFooter, PricingContainer } from './styles'
import {
  DocumentCertificationData,
  DocumentCertificationPopupProps,
  Option,
} from './types'

const options: Option[] = [{ value: 'translation', label: 'Translations' }]

export const DocumentCertificationPopup = ({
  onClose,
  onContinue,
}: DocumentCertificationPopupProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DocumentCertificationData>()

  const pages = watch('totalPages')
  const amount = useMemo(() => {
    if (pages == 1) return 35
    return pages * 25
  }, [pages])

  const onSubmit = (data: DocumentCertificationData) => {
    onContinue({
      helpWith: data.helpWith.value,
      totalPages: data.totalPages,
      fromLang: data.fromLang.value,
      toLang: data.toLang.value,
    })
  }

  return (
    <FullScreenMobileContainer as="form" onSubmit={handleSubmit(onSubmit)}>
      <PopupTopBar onClose={onClose}>Document Certifications</PopupTopBar>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={20}>
          <Text variant="regular-4">
            Looking for assistance with Certifications? Let us help you! Select
            document you need help with
          </Text>
          <Controller
            rules={{ required: true }}
            name="helpWith"
            control={control}
            render={({ field }) => (
              <SelectInput
                label="I need help with"
                size={2}
                hasError={!!errors.helpWith}
                {...field}
                options={options}
              />
            )}
          />
          <InputField
            label="How many total pages do you have?"
            type="number"
            placeholder="Add number of pages"
            min={1}
            hasError={!!errors.totalPages}
            {...register('totalPages', { required: true })}
          />
          <FromTwoContainer>
            <Controller
              rules={{ required: true }}
              name="fromLang"
              control={control}
              render={({ field }) => (
                <SelectInput
                  label="From"
                  size={2}
                  hasError={!!errors.fromLang}
                  {...field}
                  options={certificationSupportedLangList}
                />
              )}
            />

            <Controller
              rules={{ required: true }}
              name="toLang"
              control={control}
              render={({ field }) => (
                <SelectInput
                  label="To"
                  size={2}
                  hasError={!!errors.toLang}
                  {...field}
                  options={certificationSupportedLangList}
                />
              )}
            />
          </FromTwoContainer>
          <DashedDivider />
        </Flex>
        <PricingContainer>
          <Flex direction="column" gap={8}>
            <Flex justifyContent="space-between" alignItems="center" gap={8}>
              <Text variant="semibold-4">Total price</Text>
              <Text variant="semibold-4">${amount || 0}</Text>
            </Flex>
            <Text variant="regular-2" colorVar="neutral-11">
              Price is based on the amount of pages you need to translate
            </Text>
          </Flex>
        </PricingContainer>
      </FullScreenPopupContentWrapper>
      <PopupFooter>
        <Button
          variant="outline"
          colorTheme="neutral"
          size={3}
          type="button"
          onClick={onClose}
          fullWidth
          className="cancel"
        >
          Cancel
        </Button>
        <Button
          fullWidth
          variant="solid"
          colorTheme="neutral"
          size={3}
          type="submit"
        >
          Continue
        </Button>
      </PopupFooter>
    </FullScreenMobileContainer>
  )
}
