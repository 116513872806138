import {
  createContext,
  ElementRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { noop } from 'lodash'
import useApi from '@/hooks/useApi'
import { IWizardProvider } from '@/models/IWizard'
import { IVisaWizardAnswer } from '@/models/IVisaWizardAnswer'
import { HireTaxFlow } from '@/components/wizard/Flows/HireTax'
import { DocumentsCertificationFlow } from '@/components/wizard/Flows/DocumentCertification'
import { UpgradePremiumFlow } from '@/components/wizard/Flows/UpgradePremium'
import { IUserVisaWizard } from '@/models/IUserVisaWizard'
import { UserWizardResponse } from '@/utils/Api/wizard.service'
import { RequestDocumentFlow } from '@/components/wizard/Flows/RequestDocument'
import { premiumLegalUrl } from '@/constants/calendly'
import { HireAdvisorFlow } from '@/components/wizard/Flows/HireAdvisorFlow'
import { WelcomeWizardFlow } from '@/components/wizard/Flows/WelcomeWizardFlow'
import { CreateProposalFlow } from '@/components/wizard/Flows/CreateProposalFlow'
import { IProposal } from '@/models/IProposal'
import { WithdrawProposal } from '@/components/wizard/Flows/WithdrawProposal'
import { ViewProposalFlow } from '@/components/wizard/Flows/ViewProposalsFlow'
import { InsuranceFlow } from '@/components/wizard/Flows/Insurance'
import { Tab, WizardContextInterface } from './types'
import { useAuthContext } from '../Auth'

export const WizardContext = createContext<WizardContextInterface>(
  {} as WizardContextInterface,
)

export const WizardProvider = ({ children }: IWizardProvider) => {
  const { api } = useApi()
  const router = useRouter()
  const { user } = useAuthContext()
  const [messagesOpened, setMessagesOpened] = useState(false)

  const id = router.query.id as string // for user view
  const userWizardId = router.query.userWizardId as string // for advisor view

  const taxFlow = useRef<ElementRef<typeof HireTaxFlow>>(null)
  const insuranceFlow = useRef<ElementRef<typeof InsuranceFlow>>(null)
  const documentsCertificationFlow =
    useRef<ElementRef<typeof DocumentsCertificationFlow>>(null)
  const upgradePremiumFlow = useRef<ElementRef<typeof UpgradePremiumFlow>>(null)
  const requestDocumentFlow =
    useRef<ElementRef<typeof RequestDocumentFlow>>(null)
  const hireAdvisorFlow = useRef<ElementRef<typeof HireAdvisorFlow>>(null)
  const createProposalFlow = useRef<ElementRef<typeof CreateProposalFlow>>(null)
  const withdrawProposal = useRef<ElementRef<typeof WithdrawProposal>>(null)
  const viewProposalFlow = useRef<ElementRef<typeof ViewProposalFlow>>(null)

  const [userWizardResponse, setUserWizardResponse] =
    useState<UserWizardResponse>()
  const [answers, setAnswers] = useState<Record<string, IVisaWizardAnswer>>({})
  const [proposal, setProposal] = useState<IProposal | null>()

  useEffect(() => {
    // listener to reload page at the end of sprintful booking
    const listener = (e: MessageEvent) => {
      if (
        e?.data?.event?.indexOf('sprintful-') > -1 &&
        e?.data?.payload?.view == 'confirmation'
      ) {
        window.location.reload()
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [])

  useEffect(() => {
    if (!id && !userWizardId) return
    id &&
      api.wizard
        .getWizard(id as string)
        .then((data) => {
          const answers: Record<string, IVisaWizardAnswer> = {}
          data.answers.forEach((answer) => {
            answers[answer.id] = answer
          })

          setUserWizardResponse(data)
          setProposal(data.proposal)
          setAnswers(answers)
        })
        .catch(noop)
    userWizardId &&
      api.advisor
        .getCase(userWizardId)
        .then((data) => {
          const answers: Record<string, IVisaWizardAnswer> = {}
          data.answers.forEach((answer) => {
            answers[answer.id] = answer
          })

          setUserWizardResponse(data)
          setProposal(data.proposal)
          setAnswers(answers)
        })
        .catch(noop)
  }, [id, userWizardId])

  const handleAnswer = (id: string, value: IVisaWizardAnswer) => {
    const newAnswers = { ...answers }
    newAnswers[id] = value
    setAnswers(newAnswers)
  }

  const handleDocument = (document: IVisaWizardAnswer) => {
    const newCustomAnswers = { ...answers }
    newCustomAnswers[document.id] = document
    setAnswers(newCustomAnswers)
  }

  const handleTab = (tabId: Tab) => {
    if (userWizardId) {
      return router.push(`/myCases/${userWizardId}/${tabId}`)
    }
    router.push(`/wizard/${id}/${tabId}`)
  }

  const updateUserWizard = useCallback((updates: Partial<IUserVisaWizard>) => {
    setUserWizardResponse((state) =>
      state
        ? {
            ...state,
            userWizard: { ...state.userWizard, ...updates },
          }
        : state,
    )
  }, [])

  const triggerTaxAssistance = () => {
    taxFlow.current?.trigger()
  }

  const triggerHealthInsurance = () => {
    insuranceFlow.current?.trigger()
  }

  const triggerDocumentsCertification = () => {
    documentsCertificationFlow.current?.trigger()
  }

  const triggerPremium = () => {
    upgradePremiumFlow.current?.trigger()
  }

  const triggerRequestDocument = () => {
    requestDocumentFlow.current?.trigger()
  }

  const triggerHireAdvisor = () => {
    hireAdvisorFlow.current?.trigger()
  }

  const triggerCreateProposal = () => {
    createProposalFlow.current?.trigger()
  }

  const triggerWithdrawProposal = () => {
    withdrawProposal.current?.trigger()
  }

  const triggerViewProposal = () => {
    viewProposalFlow.current?.trigger()
  }

  const triggerMeeting = () => {
    const { userWizard, advisorProfile } = userWizardResponse ?? {}
    advisorProfile &&
      userWizard &&
      window.Sprintful.showPopup({
        url: `${
          advisorProfile?.freeMeetingLink ?? premiumLegalUrl
        }?hide-logo=true&hide-message=false&wizard=${userWizard.id}`,
        copyParentsQuery: 'false',
      })
  }

  const openMessages = () => setMessagesOpened(true)
  const closeMessages = () => setMessagesOpened(false)

  if (!userWizardResponse) return null

  return (
    <WizardContext.Provider
      value={{
        id: userWizardResponse.userWizard.id,
        tab: (router.query.tab as Tab) || 'overview',
        mode: userWizardId ? 'advisor' : 'user',
        userWizard: userWizardResponse.userWizard,
        prefs: userWizardResponse.prefs,
        user: userWizardResponse.user,
        advisor: userWizardResponse.advisor,
        advisorProfile: userWizardResponse.advisorProfile,
        meeting: userWizardResponse.meeting,
        proposal,
        answers,
        pendingPayments: userWizardResponse.pendingPayments,
        setTab: handleTab,
        setAnswer: handleAnswer,
        handleAnswers: setAnswers,
        updateUserWizard,
        triggerMeeting,
        triggerTaxAssistance,
        triggerHealthInsurance,
        triggerDocumentsCertification,
        triggerPremium,
        triggerRequestDocument,
        triggerHireAdvisor,
        triggerCreateProposal,
        triggerWithdrawProposal,
        triggerViewProposal,
        messagesOpened,
        openMessages,
        closeMessages,
      }}
    >
      {children}
      <HireTaxFlow ref={taxFlow} />

      <InsuranceFlow ref={insuranceFlow} />
      <DocumentsCertificationFlow ref={documentsCertificationFlow} />
      <UpgradePremiumFlow ref={upgradePremiumFlow} />
      <RequestDocumentFlow ref={requestDocumentFlow} onSave={handleDocument} />
      <HireAdvisorFlow
        ref={hireAdvisorFlow}
        advisor={userWizardResponse.advisor}
      />
      <WithdrawProposal
        ref={withdrawProposal}
        proposal={proposal}
        onUpdateProposal={() => setProposal(null)}
      />
      <WelcomeWizardFlow />
      <CreateProposalFlow
        ref={createProposalFlow}
        proposal={proposal}
        userWizardId={userWizardId}
        mode={userWizardId ? 'advisor' : 'user'}
        advisorProfile={userWizardResponse.advisorProfile}
        onUpdateProposal={(proposal: IProposal) => setProposal(proposal)}
      />
      <ViewProposalFlow ref={viewProposalFlow} />
    </WizardContext.Provider>
  )
}

export const useWizardContext = (): WizardContextInterface => {
  return useContext(WizardContext)
}
