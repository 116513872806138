import { AxiosInstance } from 'axios'
import { IVisaWizardAnswer } from '@/models/IVisaWizardAnswer'
import { IFileRef } from '@/models/IFile'
import { IUserDocCertification } from '@/models/IUserDocCertification'
import { IUserVisaWizard } from '@/models/IUserVisaWizard'
import { IUser } from '@/models/IUser'
import { IVisaSelectorPref } from '@/models/IVisaSelectorPref'
import { IPagination } from '@/models/IPagination'
import { IAdvisor } from '@/models/IAdvisor'
import { ISprintfulBooking } from '@/models/ISprintfulBooking'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import { IProposal, Prices } from '@/models/IProposal'
import { IFolder } from '@/models/IFolder'
import { IAdvisorFileTemplate } from '@/models/IAdvisorFileTemplate'

export interface WizardSaveAnswerRequest {
  itemId?: string
  answerId?: string
  label?: string
  flag?: boolean
  files?: IFileRef[]
}

export interface UpdateProposalRequest {
  summary?: string
  initialPayment?: number | null
  prices?: Prices[]
  status?: number
}

export interface WizardDocCertificationRequest {
  helpWith: string
  totalPages: number
  fromLang: string
  toLang: string
  files?: IFileRef[]
}

export interface UserWizardResponse {
  userWizard: IUserVisaWizard
  answers: IVisaWizardAnswer[]
  user: IUser
  advisor: IUser
  prefs?: IVisaSelectorPref
  advisorProfile: IAdvisor | null
  meeting: ISprintfulBooking | null
  proposal: IProposal | null
  folders?: IFolder[] | null
  files?: IAdvisorFileTemplate[] | null
  pendingPayments: IUserVisaWizardPayment[]
}

interface GetWizardsRequest {
  search?: string
  token?: string
}

interface WizardsCountResponse {
  unread: number
  wizards: number
}

export class WizardService {
  constructor(private instance: AxiosInstance) {}

  getAll = (params: GetWizardsRequest) => {
    return this.instance
      .get<IPagination & { userWizards: IUserVisaWizard[] }>(`/wizard`, {
        params,
      })
      .then((response) => response.data)
  }

  createWizard = (advisorId: string) => {
    return this.instance
      .post<IUserVisaWizard>(`/wizard/${advisorId}/setup-free-wizard`)
      .then((response) => response.data)
  }

  getWizard = (id: string) => {
    return this.instance
      .get<UserWizardResponse>(`/wizard/${id}`)
      .then((response) => response.data)
  }

  deleteWizard = (id: string) => {
    return this.instance
      .delete(`/wizard/${id}`)
      .then((response) => response.data)
  }

  getWizardStatus = (id: string) => {
    return this.instance
      .get<IUserVisaWizard>(`/wizard/${id}/status`)
      .then((response) => response.data)
  }

  uploadFiles = (data: FormData) => {
    return this.instance
      .post<IFileRef[]>('/wizard/files', data)
      .then((response) => response.data)
  }

  saveAnswer = (id: string, data: WizardSaveAnswerRequest) => {
    return this.instance
      .post<IVisaWizardAnswer>(`/wizard/${id}/answer`, data)
      .then((response) => response.data)
  }

  deleteAnswer = (id: string, itemId: string) => {
    return this.instance
      .delete(`/wizard/${id}/answer/${itemId}`)
      .then((response) => response.data)
  }

  requestDocCertification = (
    id: string,
    data: WizardDocCertificationRequest,
  ) => {
    return this.instance
      .post<IUserDocCertification>(`/wizard/${id}/certifications`, data)
      .then((response) => response.data)
  }

  getDocCertification = (id: string, certificationId: string) => {
    return this.instance
      .get<IUserDocCertification>(
        `/wizard/${id}/certifications/${certificationId}`,
      )
      .then((response) => response.data)
  }

  hireAdvisor = (id: string) =>
    this.instance.patch(`/wizard/${id}/hire`).then((response) => response.data)

  getPayments = (id: string) =>
    this.instance
      .get<IPagination & { payments: IUserVisaWizardPayment[] }>(
        `/wizard/${id}/payments`,
      )
      .then((response) => response.data)

  findWizardByAdvisorId = (id: string) =>
    this.instance
      .get<IUserVisaWizard>(`/wizard/find-wizard/${id}`)
      .then((response) => response.data)

  getUserWizardsCount = () =>
    this.instance
      .get<WizardsCountResponse>(`/wizard/wizards-count`)
      .then((response) => response.data)

  updateProposal = (id: string, body: UpdateProposalRequest) =>
    this.instance
      .patch<{ proposal: IProposal; initialPayment: IUserVisaWizardPayment }>(
        `/proposal/update-proposal/${id}`,
        body,
      )
      .then((response) => response.data)

  getProposals = (id: string) =>
    this.instance
      .get<IProposal[]>(`/wizard/${id}/proposals`)
      .then((response) => response.data)
}
