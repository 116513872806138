import styled, { css } from 'styled-components'
import {
  Flex,
  PopupPadding,
  Text,
  textVariants,
  FileField as FileFieldBase,
  TextField,
} from '@/components/ui'
import {
  DefaultPopupFooter,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
  thinModalScrollbar,
} from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const PopupContainer = styled(FullScreenMobileContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  ${Media.Tablet`
    display: block;
    width: 768px;
    height: auto;    
  `}
  ${Media.Laptop`
      display: block;
      width: 882px;
   `}
  .file-upload-topbar {
    border-bottom: none;
    ${Media.Tablet`
      border-bottom: 1px solid var(--neutral-alpha-4);
      `}
  }
`

export const PopupGridWrapper = styled(FullScreenPopupContentWrapper)`
  padding: 0;
`

export const PopupGrid = styled(Flex)`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`

export const PopupContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: auto;
  ${thinModalScrollbar}
`

export const PopupFooter = styled(DefaultPopupFooter)<{ direction?: string }>`
  border-top: transparent;
  ${(p) =>
    p.direction &&
    css`
      flex-direction: ${p.direction};
      gap: 8px;
    `}
  flex-shrink: 0;
  a {
    width: 100%;
  }
`

export const Tab = styled.div<{ selected?: boolean }>`
  ${textVariants['medium-3']}
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid
    ${(p) => (p.selected ? 'var(--neutral-12)' : 'var(--neutral-alpha-6)')};
  width: 50%;
  color: ${(p) => (p.selected ? 'var(--neutral-12)' : 'var(--neutral-11)')};
  &:hover {
    color: var(--neutral-12);
  }
`

export const MarkdownContent = styled.div`
  ${textVariants['regular-4']}
  flex-shrink: 1;
  overflow: auto;

  a {
    color: var(--purple-500);
  }
`

export const DocumentPreview = styled(Flex)<{
  isViewer?: boolean
  selectedTab?: boolean
}>`
  width: 100%;
  ${(p) =>
    p.isViewer &&
    css`
      height: 100%;
    `}
  background-color: ${(p) =>
    p.isViewer ? 'var(--panel-1)' : 'var(--neutral-2)'};
  flex-direction: column;
  display: ${(p) => (p.selectedTab ? 'flex' : 'none')};
  ${Media.Tablet`
    display: flex;
  `}
`

export const UploadSection = styled(Flex)<{ selectedTab?: boolean }>`
  width: 100%;
  flex-direction: column;
  display: ${(p) => (p.selectedTab ? 'flex' : 'none')};
  ${Media.Tablet`
    display: flex;
  `}
`

export const FileContainer = styled.div`
  height: 280px;
  flex-shrink: 0;
  position: relative;
  background-color: var(--neutral-alpha-3);
  border-radius: 12px;
  margin: 20px 0px;
  padding: 30px 47px 0 47px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

export const FilePreview = styled(Flex)<{ url: string }>`
  background-image: url('${(p) => p.url}');
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const FileList = styled.ul`
  margin: 16px 0px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    padding: 0px;
  }

  i {
    margin-right: 8px;
    color: var(--grey-500);
    flex-shrink: 0;
  }

  button {
    flex-shrink: 0;
  }

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`
export const FileItem = styled.li`
  display: flex;
  align-items: center;
`

export const FileName = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FileField = styled(FileFieldBase)`
  margin-bottom: 20px;
  flex-shrink: 0;
`

export const LabelFieldWrapper = styled(Flex)`
  margin-bottom: 16px;
`
