import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export const textVariants = {
  'heading-h3': css`
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: -0.6px;
  `,

  'heading-h4': css`
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.52px;
  `,

  'heading-semibold-7': css`
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.1px;
  `,

  'heading-h5': css`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
  `,

  'body-l-semibold': css`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.34px;
  `,

  'body-l-regular': css`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.34px;
  `,

  'body-m-semibold': css`
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.15px;
  `,

  'body-m-regular': css`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.15px;
  `,

  'body-s-semibold': css`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
  `,

  'body-s-medium': css`
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
  `,

  'body-s-regular': css`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
  `,

  'caption-s-medium': css`
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0px;
  `,

  'other-nav-bar': css`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2px;
  `,

  'medium-3': css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  `,
  'medium-4': css`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  'semibold-3': css`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  `,
  'semibold-4': css`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  'semibold-5': css`
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  `,
  'semibold-8': css`
    font-family: var(--font-inter-display);
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  `,
  'regular-4': css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  'semibold-6': css`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
  `,
  'semibold-7': css`
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.1px;
  `,
  'semibold-2': css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  `,
  'regular-3': css`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  'medium-2': css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
  'medium-5': css`
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  `,
  'medium-10': css`
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
  `,
  'medium-6': css`
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
  `,

  'regular-2': css`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  'regular-5': css`
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  `,
  'regular-6': css`
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
  `,
}
type Variant = keyof typeof textVariants

const Container = styled.p<{ variant: Variant; colorVar?: string }>`
  ${(p) => textVariants[p.variant]}
  color: ${(p) => (p.colorVar ? `var(--${p.colorVar})` : 'inherit')};
`

interface TextProps extends HTMLAttributes<HTMLElement> {
  variant?: keyof typeof textVariants
  colorVar?: string
  as?: React.ElementType
}

const Component: React.FC<TextProps> = ({
  variant,
  children,
  as = 'p',
  ...rest
}) => {
  return (
    <Container as={as} variant={variant || 'body-l-regular'} {...rest}>
      {children}
    </Container>
  )
}

export const Text = React.memo(Component)
