import React from 'react'
import { Flex, Icon, Text } from '@/components/ui'
import {
  Divider,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
  MobileOnlyContainer,
} from '@/components/uiJar/utilityStyles'
import { PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { KeyIcon, PopupFooter, Steps } from './styles'
import { BaseWizardPopupProps } from '../types'

interface HireTaxPopupProps extends BaseWizardPopupProps {
  onConfirm: () => void
}

export const HireTaxPopup = ({ onConfirm, onClose }: HireTaxPopupProps) => {
  return (
    <FullScreenMobileContainer>
      <MobileOnlyContainer>
        <PopupTopBar onClose={onClose}>Taxes</PopupTopBar>
      </MobileOnlyContainer>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={20}>
          <KeyIcon>
            <Icon.Solid name="serverStorage" size={20} />
          </KeyIcon>
          <Flex direction="column" gap={4}>
            <Text variant="semibold-6">
              Tax help for digital nomads & expats
            </Text>
            <Text variant="regular-3" colorVar="neutral-11">
              Need help with your taxes? Talk to one of our experts!
            </Text>
          </Flex>
          <Divider />
          <Flex direction="column" gap={2}>
            <Text variant="semibold-4">
              Two easy steps to get help with your taxes
            </Text>
            <Text variant="regular-3" colorVar="neutral-11">
              Book a consultation and talk with one of our tax experts
            </Text>
          </Flex>
          <Steps>
            <li>
              <Icon.Solid name="checkCircle" size={20} />
              <Flex direction="column" gap={8}>
                <Text variant="medium-3" colorVar="accent-11">
                  Step 1
                </Text>
                <Text variant="regular-4">
                  Book a consultation with our tax expert specialized in
                  international taxation to get an initial assessment of your
                  personal situation
                </Text>
              </Flex>
            </li>
            <li>
              <Icon.Solid name="checkCircle" size={20} />
              <Flex direction="column" gap={8}>
                <Text variant="medium-3" colorVar="accent-11">
                  Step 2
                </Text>
                <Text variant="regular-4">
                  We’ll provide presonalized recommendations and guide you in
                  any futher steps to take
                </Text>
              </Flex>
            </li>
          </Steps>
        </Flex>
      </FullScreenPopupContentWrapper>
      <PopupFooter>
        <Button
          variant="outline"
          colorTheme="neutral"
          size={3}
          onClick={onClose}
          fullWidth
          className="cancel"
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          colorTheme="neutral"
          size={3}
          onClick={onConfirm}
          fullWidth
        >
          Book Meeting
        </Button>
      </PopupFooter>
    </FullScreenMobileContainer>
  )
}
