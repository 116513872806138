import styled from 'styled-components'
import { Flex, Text, PopupPadding, textVariants } from '@/components/ui'
import { DefaultPopupFooter } from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const PricingContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px;
`

export const FromTwoContainer = styled(Flex)`
  gap: 16px;

  > label {
    flex: 1;
  }
`
export const PopupFooter = styled(DefaultPopupFooter)`
  border-top: transparent;
  .cancel {
    display: none;
  }

  ${Media.Tablet`
    border-top: 1px solid var(--neutral-alpha-4);
    .cancel {
      display: block;
    }
    `}
`
