import React, { PropsWithChildren, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'

import { textVariants } from '@/components/ui/Text'
import { Flex } from '@/components/ui/Flex'
import { Media } from '@/utils/media'
import { useUIState } from '@/contexts/UIState'
import { useTheme } from '@/contexts/Theme'
import { Button } from '../Button'
import { IconLink } from '../IconLink'

interface PopupProps {
  isOpen: boolean
  children: React.ReactNode
  onClose?: () => void
  fullScreenInMobile?: boolean
  preferredTheme?: 'light' | 'dark'
}

interface TopBarProps extends PropsWithChildren {
  onClose?: () => void
  useDefaultStyling?: boolean
  noBorder?: boolean
  className?: string
}

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fullScreenInMobile'].includes(prop),
})<{ fullScreenInMobile?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  overflow-y: auto;
  padding: ${(p) => (p.fullScreenInMobile ? '0' : '42px 0')};

  ${Media.Tablet`
    padding: 42px 0;
  `}
`

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-dark-11);
`

const ContentWrapper = styled.div<{ fullScreenInMobile?: boolean }>`
  position: relative;
  display: flex;
  ${({ fullScreenInMobile }) =>
    fullScreenInMobile &&
    css`
      ${Media.Tablet`
        width: fit-content;
        margin: 0 auto;
    `}
    `}
  justify-content: center;
  align-items: center;
  min-height: 100%;
`

const Content = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['fullScreenInMobile'].includes(prop),
})<{ fullScreenInMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: ${({ fullScreenInMobile }) =>
    fullScreenInMobile ? 0 : '16px'};
  width: ${({ fullScreenInMobile }) => (fullScreenInMobile ? '100%' : 'auto')};
  background-color: var(--panel-1);
  border: ${({ fullScreenInMobile }) =>
    fullScreenInMobile ? 'none' : '1px solid var(--neutral-alpha-4)'};
  z-index: 1;
  ${Media.Tablet`
    width: auto;
   border-radius: 16px;
  `}
`

export const TopBar = styled.div<{
  hasChildren: boolean
  useDefaultStyling?: boolean
  noBorder?: boolean
}>`
  position: relative;
  ${textVariants['semibold-4']}
  color: var(--neutral-12);
  padding: 0 16px;
  height: 56px;
  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : '1px solid var(--neutral-alpha-4)'};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${(p) =>
    p.useDefaultStyling &&
    css`
      justify-content: space-between;
    `}

  ${Media.Tablet`
    justify-content: space-between;
    height: 64px;
    padding: 14px 16px;
  `}

  ${(p) =>
    !p.hasChildren &&
    css`
      border-bottom: none;
      position: absolute;
      right: 0;
    `}
`

const TopbarTitle = styled.div<{ useDefaultStyling?: boolean }>`
  ${(p) =>
    !p.useDefaultStyling &&
    css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `}
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${Media.Tablet`
    position: relative;
    transform: none;
    max-width: none;
    left: 0;
    top: 0;
    `}
`
const TopbarDesktopCloseButton = styled.div<{ renderInMobile?: boolean }>`
  display: ${({ renderInMobile }) => (renderInMobile ? 'block' : 'none')};
  ${Media.Tablet`
      display: block;
      `}
`
const TopbarMobileCloseButton = styled.div`
  display: block;
  ${Media.Tablet`
      display: none;
      `}
`

export const PopupActions = styled(Flex)`
  padding: 16px 20px;
  justify-content: flex-end;
  border-top: 1px solid var(--neutral-alpha-4);
  gap: 8px;
  flex-shrink: 0;
`

export const PopupTopBar: React.FC<TopBarProps> = ({
  children,
  onClose,
  useDefaultStyling,
  noBorder,
  className,
}) => {
  return (
    <TopBar
      hasChildren={!!children}
      useDefaultStyling={useDefaultStyling}
      noBorder={noBorder}
      className={className}
    >
      {onClose && !useDefaultStyling && (
        <TopbarMobileCloseButton>
          <IconLink
            size={4}
            colorTheme="neutral"
            name="arrowLeft"
            type="Solid"
            onClick={onClose}
          />
        </TopbarMobileCloseButton>
      )}
      <TopbarTitle useDefaultStyling={useDefaultStyling}>
        {children}
      </TopbarTitle>
      {onClose && (
        <TopbarDesktopCloseButton renderInMobile={useDefaultStyling}>
          <Button
            onClick={onClose}
            variant="ghost"
            size={2}
            colorTheme="neutral"
            iconButton
            icon={{ startIconName: 'xMarkSmall', type: 'Solid' }}
          />
        </TopbarDesktopCloseButton>
      )}
    </TopBar>
  )
}

export const Popup: React.FC<PopupProps> = ({
  isOpen,
  children,
  onClose,
  fullScreenInMobile,
  preferredTheme,
}): JSX.Element | null => {
  const mainElement = useRef<HTMLElement | null>(null)
  const { incrementOpenedPopupsCount, decrementOpenedPopupsCount } =
    useUIState()

  const { theme } = useTheme()
  const popupTheme = preferredTheme || theme

  useEffect(() => {
    mainElement.current = document.getElementById('main')
  }, [])

  useEffect(() => {
    if (isOpen) {
      incrementOpenedPopupsCount()
      return () => {
        decrementOpenedPopupsCount()
      }
    }
  }, [isOpen, incrementOpenedPopupsCount, decrementOpenedPopupsCount])

  if (!isOpen || !mainElement.current) return null
  return (
    <>
      {createPortal(
        <Container
          className={popupTheme === 'dark' ? 'dark' : ''}
          fullScreenInMobile={fullScreenInMobile}
        >
          <Background onClick={onClose} />
          <ContentWrapper fullScreenInMobile={fullScreenInMobile}>
            <Content
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.2 }}
              fullScreenInMobile={fullScreenInMobile}
            >
              {children}
            </Content>
          </ContentWrapper>
        </Container>,
        mainElement.current!,
      )}
    </>
  )
}
