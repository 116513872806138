import styled from 'styled-components'
import { CRImage, Flex, Text } from '@/components/ui'
import { DefaultPopupFooter } from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const KeyIcon = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--accent-alpha-3);
  justify-content: center;
  align-items: center;
  i {
    color: var(--accent-11);
  }
`

export const Cover = styled(CRImage)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 224px;
  display: flex;
  justify-content: center;
  padding-left: 40px;
  gap: 8px;

  p {
    width: 45%;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`

export const Steps = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 0px;
  ${Media.Tablet`
      margin-bottom: 32px;
    `}
  li {
    display: flex;
    gap: 16px;
    i {
      color: var(--accent-9);
      flex-shrink: 0;
    }
  }
`

export const PopupFooter = styled(DefaultPopupFooter)`
  border-top: transparent;
  .cancel {
    display: none;
  }

  ${Media.Tablet`
    border-top: 1px solid var(--neutral-alpha-4);
    .cancel {
      display: block;
    }
    `}
`
