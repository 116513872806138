import styled, { css } from 'styled-components'
import { Media } from '@/utils/media'
import { Flex } from '@/components/ui/Flex'

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--neutral-alpha-4);
`
export const DashedDivider = styled.div`
  width: 100%;
  border-bottom: 1px dashed var(--neutral-alpha-4);
`
export const ButtonNoWrapContainer = styled.div`
  flex-shrink: 0;
`
export const modalScrollbar = css`
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: background-color 1s ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
    transition: background-color 1s ease-in-out;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: var(--neutral-alpha-3); /* Show when hover */
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--neutral-alpha-6); /* Show when scrolling */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--neutral-alpha-7);
  }
`

export const thinModalScrollbar = css`
  ${modalScrollbar}
  &::-webkit-scrollbar {
    width: 4px;
  }
`

export const thinHorizontalStyledScrollbar = css`
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: var(--neutral-alpha-3);
    border-radius: 9999px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral-alpha-6);
    border-radius: 99px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--neutral-alpha-7);
  }
`

export const FullScreenMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  ${Media.Tablet`
    display: block;
    width: 480px;
    height: auto;    
 `}
`
export const DefaultPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  ${Media.Tablet`
    width: 480px;
    height: auto;    
 `}
`

export const DefaultPopupFooter = styled.div`
  border-top: 1px solid var(--neutral-alpha-4);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
  ${Media.Tablet`
      flex-direction: row;
      `}
`

export const FullScreenPopupContentWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  ${thinModalScrollbar}
  ${Media.Tablet`
    padding: 20px;
    min-height: 480px;
    max-height: 542px;
      `}
`

export const MobileOnlyContainer = styled.div`
  ${Media.Tablet`
  display: none;
  `}
`
export const DesktopOnlyContainer = styled.div`
  display: none;
  ${Media.Tablet`
  display: block;
  `}
`

export const DefaultPopupContentWrapper = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${modalScrollbar}
`
