import styled from 'styled-components'
import BaseAvatarStatus from '../uiJar/BaseAvatarStatus'

export interface AvatarProps {
  size?: number
  url?: string
}

export type AvatarWithStatusProps = AvatarProps & {
  statusSize: 1 | 2 | 3 | 4 | 5 | 6
  statusTheme: 'neutral' | 'success' | 'danger'
}

export const Avatar = styled.i<AvatarProps>`
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  background-position: center;
  background-size: cover;
  border-radius: 999px;
  width: ${(p) => p.size || 24}px;
  height: ${(p) => p.size || 24}px;
  background-image: url('${(p) => (p.url ? p.url : '/app/img/avatar.png')}');
  background-color: var(--neutral-alpha-3);
`

const StatusWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const AvatarWithStatus: React.FC<AvatarWithStatusProps> = ({
  size,
  url,
  statusSize,
  statusTheme,
}) => {
  return (
    <Avatar size={size} url={url}>
      <StatusWrapper>
        <BaseAvatarStatus size={statusSize} colorTheme={statusTheme} />
      </StatusWrapper>
    </Avatar>
  )
}
