import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { noop } from 'lodash'
import { PaymentPopup } from '@/components/ui'
import { PaymentReason } from '@/constants/payments'
import { IFileRef } from '@/models/IFile'
import useApi from '@/hooks/useApi'
import { useWizardContext } from '@/contexts/Wizard'
import { WizardDocCertificationRequest } from '@/utils/Api/wizard.service'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { IUserDocCertification } from '@/models/IUserDocCertification'
import { CreateDocCertificationIntent } from '@/utils/Api/payments.service'
import { Popup } from '@/components/uiJar/popup'
import {
  CongratulationsPopup,
  DocumentCertificationPopup,
  FileUploadPopup,
} from '../Popups'

export const DocumentsCertificationFlow = forwardRef<{ trigger: () => void }>(
  (_, ref) => {
    const { api } = useApi()
    const router = useRouter()
    const { userWizard } = useWizardContext()

    const [step, setStep] = useState(0)
    const [isTriggered, setTriggered] = useState(false)
    const [request, setRequest] = useState<WizardDocCertificationRequest>()
    const [data, setData] = useState<IUserDocCertification>()

    const [storedId, storeId] = useLocalStorage<string | undefined>(
      `w_${userWizard.id}_doc`,
      undefined,
    )

    const handleClose = () => {
      setStep(0)
      setTriggered(false)
    }

    useEffect(() => {
      const flow = new URLSearchParams(window.location.search).get('flow')
      if (flow && flow === 'certification' && storedId) {
        api.wizard
          .getDocCertification(userWizard.id, storedId)
          .then((data) => {
            setData(data)
            setStep(2)
            setTriggered(true)
          })
          .catch(noop)
      }
    }, [])

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    const handleFiles = (files: IFileRef[]) => {
      if (request) {
        api.wizard
          .requestDocCertification(userWizard.id, {
            ...request,
            files,
          })
          .then((data) => {
            storeId(data.id)
            setData(data)
            setStep(2)
          })
          .catch(noop)
        setStep(2)
      }
    }

    const returnUrl = `${
      new URL(location.pathname, location.href).href
    }?flow=certification`

    if (!isTriggered) return null

    return (
      <>
        <Popup fullScreenInMobile isOpen={step === 0}>
          <DocumentCertificationPopup
            onClose={handleClose}
            onContinue={(data) => {
              setRequest(data)
              setStep(1)
            }}
          />
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 1}>
          <FileUploadPopup onClose={handleClose} onSubmit={handleFiles} />
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 2 && !!data}>
          <PaymentPopup<CreateDocCertificationIntent>
            wizardId={userWizard.id}
            certificationId={data?.id || ''}
            type={PaymentReason.DocumentCertification}
            returnUrl={returnUrl}
            onClose={handleClose}
            onSuccess={() => {
              setStep(3)
            }}
          />
        </Popup>
        <Popup isOpen={step === 3}>
          <CongratulationsPopup
            title="Congratulations!"
            message="All translated documents will be emailed to you within next 3 to 5 business days."
            onClose={() => {
              router.replace(`/wizard/${userWizard.id}`)
              handleClose()
            }}
          />
        </Popup>
      </>
    )
  },
)

DocumentsCertificationFlow.displayName = 'DocumentsCertificationFlow'
