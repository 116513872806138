import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Icon, Text } from '@/components/ui'
import { FullScreenMobileContainer } from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import { PopupTopBar } from '@/components/uiJar/popup'
import {
  Actions,
  PopupContent,
  Subscript,
  Subtitle,
  TopbarWrapper,
} from './styles'
import { CongratulationsPopupProps } from './types'

export const CongratulationsPopup = ({
  title,
  message,
  children,
  hideReceipt,
  onClose,
  variant = 'congratulation',
  topBarTitle,
}: CongratulationsPopupProps) => {
  const handleContinue = () => {
    onClose()
  }
  return (
    <FullScreenMobileContainer>
      {topBarTitle && (
        <TopbarWrapper>
          <PopupTopBar onClose={onClose}>
            <Text variant="semibold-4">Request Proposal</Text>
          </PopupTopBar>
        </TopbarWrapper>
      )}
      <PopupContent variant={variant}>
        <Icon.Solid
          name={
            variant === 'congratulation' ? 'checkCircle' : 'informationCircle'
          }
          size={48}
        />
        <Flex direction="column" gap={4}>
          <Text variant="semibold-4">{title}</Text>
          <Subtitle>
            {message && <ReactMarkdown>{message}</ReactMarkdown>}
            {children}
          </Subtitle>
        </Flex>

        {!hideReceipt && (
          <Subscript variant="body-s-regular">
            Receipt has been sent to your email.
          </Subscript>
        )}
      </PopupContent>
      <Actions>
        <Button
          fullWidth
          variant="solid"
          size={3}
          colorTheme="neutral"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Actions>
    </FullScreenMobileContainer>
  )
}
