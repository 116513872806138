import chatBubbleOvalLeftGrey from './chat-bubble-oval-left-grey.svg'
import academicCap from './academic-cap.svg'
import adjustmentsHorizontal from './adjustments-horizontal.svg'
import adjustmentsVertical from './adjustments-vertical.svg'
import archiveBoxArrowDown from './archive-box-arrow-down.svg'
import archiveBoxXMark from './archive-box-x-mark.svg'
import archiveBox from './archive-box.svg'
import arrowDownCircle from './arrow-down-circle.svg'
import arrowDownLeft from './arrow-down-left.svg'
import arrowDownOnSquareStack from './arrow-down-on-square-stack.svg'
import arrowDownOnSquare from './arrow-down-on-square.svg'
import arrowDownRight from './arrow-down-right.svg'
import arrowDownTray from './arrow-down-tray.svg'
import arrowDown from './arrow-down.svg'
import arrowLeftCircle from './arrow-left-circle.svg'
import arrowLeftOnRectangle from './arrow-left-on-rectangle.svg'
import arrowLeft from './arrow-left.svg'
import arrowLongDown from './arrow-long-down.svg'
import arrowLongLeft from './arrow-long-left.svg'
import arrowLongRight from './arrow-long-right.svg'
import arrowLongUp from './arrow-long-up.svg'
import arrowPathRoundedSquare from './arrow-path-rounded-square.svg'
import arrowPath from './arrow-path.svg'
import arrowRightCircle from './arrow-right-circle.svg'
import arrowRightOnRectangle from './arrow-right-on-rectangle.svg'
import arrowRight from './arrow-right.svg'
import arrowTopRightOnSquare from './arrow-top-right-on-square.svg'
import arrowTrendingDown from './arrow-trending-down.svg'
import arrowTrendingUp from './arrow-trending-up.svg'
import arrowUpCircle from './arrow-up-circle.svg'
import arrowUpLeft from './arrow-up-left.svg'
import arrowUpOnSquareStack from './arrow-up-on-square-stack.svg'
import arrowUpOnSquare from './arrow-up-on-square.svg'
import arrowUpRight from './arrow-up-right.svg'
import arrowUpTray from './arrow-up-tray.svg'
import arrowUp from './arrow-up.svg'
import arrowUturnDown from './arrow-uturn-down.svg'
import arrowUturnLeft from './arrow-uturn-left.svg'
import arrowUturnRight from './arrow-uturn-right.svg'
import arrowUturnUp from './arrow-uturn-up.svg'
import arrowsPointingIn from './arrows-pointing-in.svg'
import arrowsPointingOut from './arrows-pointing-out.svg'
import arrowsRightLeft from './arrows-right-left.svg'
import arrowsUpDown from './arrows-up-down.svg'
import atSymbol from './at-symbol.svg'
import backspace from './backspace.svg'
import backward from './backward.svg'
import banknotes from './banknotes.svg'
import bars2 from './bars-2.svg'
import bars3BottomLeft from './bars-3-bottom-left.svg'
import bars3BottomRight from './bars-3-bottom-right.svg'
import bars3CenterLeft from './bars-3-center-left.svg'
import bars3 from './bars-3.svg'
import bars4 from './bars-4.svg'
import barsArrowDown from './bars-arrow-down.svg'
import barsArrowUp from './bars-arrow-up.svg'
import battery0 from './battery-0.svg'
import battery100 from './battery-100.svg'
import battery50 from './battery-50.svg'
import beaker from './beaker.svg'
import bellAlert from './bell-alert.svg'
import bellSlash from './bell-slash.svg'
import bellSnooze from './bell-snooze.svg'
import bell from './bell.svg'
import boltSlash from './bolt-slash.svg'
import bolt from './bolt.svg'
import bookOpen from './book-open.svg'
import bookmarkSlash from './bookmark-slash.svg'
import bookmarkSquare from './bookmark-square.svg'
import bookmark from './bookmark.svg'
import briefcase from './briefcase.svg'
import bugAnt from './bug-ant.svg'
import buildingLibrary from './building-library.svg'
import buildingOffice2 from './building-office-2.svg'
import buildingOffice from './building-office.svg'
import buildingStorefront from './building-storefront.svg'
import cake from './cake.svg'
import calculator from './calculator.svg'
import calendarDays from './calendar-days.svg'
import calendar from './calendar.svg'
import camera from './camera.svg'
import chartBarSquare from './chart-bar-square.svg'
import chartBar from './chart-bar.svg'
import chartPie from './chart-pie.svg'
import chatBubbleBottomCenterText from './chat-bubble-bottom-center-text.svg'
import chatBubbleBottomCenter from './chat-bubble-bottom-center.svg'
import chatBubbleLeftEllipsis from './chat-bubble-left-ellipsis.svg'
import chatBubbleLeftRight from './chat-bubble-left-right.svg'
import chatBubbleLeft from './chat-bubble-left.svg'
import chatBubbleOvalLeftEllipsis from './chat-bubble-oval-left-ellipsis.svg'
import chatBubbleOvalLeft from './chat-bubble-oval-left.svg'
import checkBadge from './check-badge.svg'
import checkCircle from './check-circle.svg'
import check from './check.svg'
import chevronDoubleDown from './chevron-double-down.svg'
import chevronDoubleLeft from './chevron-double-left.svg'
import chevronDoubleRight from './chevron-double-right.svg'
import chevronDoubleUp from './chevron-double-up.svg'
import chevronDown from './chevron-down.svg'
import chevronLeft from './chevron-left.svg'
import chevronRight from './chevron-right.svg'
import chevronUpDown from './chevron-up-down.svg'
import chevronUp from './chevron-up.svg'
import circleStack from './circle-stack.svg'
import clipboardDocumentCheck from './clipboard-document-check.svg'
import clipboardDocumentList from './clipboard-document-list.svg'
import clipboardDocument from './clipboard-document.svg'
import clipboard from './clipboard.svg'
import clock from './clock.svg'
import cloudArrowDown from './cloud-arrow-down.svg'
import cloudArrowUp from './cloud-arrow-up.svg'
import cloud from './cloud.svg'
import codeBracketSquare from './code-bracket-square.svg'
import codeBracket from './code-bracket.svg'
import cog6Tooth from './cog-6-tooth.svg'
import cog8Tooth from './cog-8-tooth.svg'
import cog from './cog.svg'
import commandLine from './command-line.svg'
import computerDesktop from './computer-desktop.svg'
import cpuChip from './cpu-chip.svg'
import creditCard from './credit-card.svg'
import cubeTransparent from './cube-transparent.svg'
import cube from './cube.svg'
import currencyBangladeshi from './currency-bangladeshi.svg'
import currencyDollar from './currency-dollar.svg'
import currencyEuro from './currency-euro.svg'
import currencyPound from './currency-pound.svg'
import currencyRupee from './currency-rupee.svg'
import currencyYen from './currency-yen.svg'
import cursorArrowRays from './cursor-arrow-rays.svg'
import cursorArrowRipple from './cursor-arrow-ripple.svg'
import devicePhoneMobile from './device-phone-mobile.svg'
import deviceTablet from './device-tablet.svg'
import documentArrowDown from './document-arrow-down.svg'
import documentArrowUp from './document-arrow-up.svg'
import documentChartBar from './document-chart-bar.svg'
import documentCheck from './document-check.svg'
import documentDuplicate from './document-duplicate.svg'
import documentMagnifyingGlass from './document-magnifying-glass.svg'
import documentMinus from './document-minus.svg'
import documentPlus from './document-plus.svg'
import documentText from './document-text.svg'
import document from './document.svg'
import dotGrid from './dot-grid.svg'
import ellipsisHorizontalCircle from './ellipsis-horizontal-circle.svg'
import ellipsisHorizontal from './ellipsis-horizontal.svg'
import ellipsisVertical from './ellipsis-vertical.svg'
import envelopeOpen from './envelope-open.svg'
import envelope from './envelope.svg'
import exclaimationCircle from './exclaimation-circle.svg'
import exclamationTriangle from './exclamation-triangle.svg'
import eyeDropper from './eye-dropper.svg'
import eyeSlash from './eye-slash.svg'
import eye from './eye.svg'
import faceFrown from './face-frown.svg'
import faceSmile from './face-smile.svg'
import film from './film.svg'
import fingerPrint from './finger-print.svg'
import fire from './fire.svg'
import flag from './flag.svg'
import folderArrowDown from './folder-arrow-down.svg'
import folderMinus from './folder-minus.svg'
import folderOpen from './folder-open.svg'
import folderPlus from './folder-plus.svg'
import folder from './folder.svg'
import forward from './forward.svg'
import funnel from './funnel.svg'
import gif from './gif.svg'
import giftTop from './gift-top.svg'
import gift from './gift.svg'
import globe from './globe.svg'
import globeAmericas from './globe-americas.svg'
import globeAsiaAustralia from './globe-asia-australia.svg'
import globeEuropeAfrica from './globe-europe-africa.svg'
import handRaised from './hand-raised.svg'
import handThumbDown from './hand-thumb-down.svg'
import handThumbUp from './hand-thumb-up.svg'
import hashtag from './hashtag.svg'
import heart from './heart.svg'
import homeModern from './home-modern.svg'
import home from './home.svg'
import identification from './identification.svg'
import inboxArrowDown from './inbox-arrow-down.svg'
import inboxStack from './inbox-stack.svg'
import inbox from './inbox.svg'
import informationCircle from './information-circle.svg'
import key from './key.svg'
import language from './language.svg'
import lifebuoy from './lifebuoy.svg'
import lightBulb from './light-bulb.svg'
import link from './link.svg'
import listBullet from './list-bullet.svg'
import lockClosed from './lock-closed.svg'
import lockOpen from './lock-open.svg'
import magnifyingGlassCircle from './magnifying-glass-circle.svg'
import magnifyingGlassMinus from './magnifying-glass-minus.svg'
import magnifyingGlassPlus from './magnifying-glass-plus.svg'
import magnifyingGlass from './magnifying-glass.svg'
import mapPin from './map-pin.svg'
import map from './map.svg'
import megaphone from './megaphone.svg'
import microphone from './microphone.svg'
import minusCircle from './minus-circle.svg'
import minus from './minus.svg'
import moon from './moon.svg'
import musicalNote from './musical-note.svg'
import newspaper from './newspaper.svg'
import noSymbol from './no-symbol.svg'
import paintBrush from './paint-brush.svg'
import paperAirplane from './paper-airplane.svg'
import paperClip from './paper-clip.svg'
import pauseCircle from './pause-circle.svg'
import pause from './pause.svg'
import pencilSquare from './pencil-square.svg'
import pencil from './pencil.svg'
import phoneArrowDownLeft from './phone-arrow-down-left.svg'
import phoneArrowUpRight from './phone-arrow-up-right.svg'
import phoneXMark from './phone-x-mark.svg'
import phone from './phone.svg'
import photo from './photo.svg'
import playCircle from './play-circle.svg'
import playPause from './play-pause.svg'
import play from './play.svg'
import plusCircle from './plus-circle.svg'
import miniPlusCircle from './mini-plus-circle.svg'
import plus from './plus.svg'
import power from './power.svg'
import presentationChartBar from './presentation-chart-bar.svg'
import presentationChartLine from './presentation-chart-line.svg'
import printer from './printer.svg'
import puzzlePiece from './puzzle-piece.svg'
import qrCode from './qr-code.svg'
import questionMarkCircle from './question-mark-circle.svg'
import queueList from './queue-list.svg'
import radio from './radio.svg'
import receiptPercent from './receipt-percent.svg'
import receiptRefund from './receipt-refund.svg'
import rectangleGroup from './rectangle-group.svg'
import rectangleStack from './rectangle-stack.svg'
import rocketLaunch from './rocket-launch.svg'
import rss from './rss.svg'
import scale from './scale.svg'
import scissors from './scissors.svg'
import serverStack from './server-stack.svg'
import server from './server.svg'
import share from './share.svg'
import shieldCheck from './shield-check.svg'
import shieldExclamation from './shield-exclamation.svg'
import shoppingBag from './shopping-bag.svg'
import shoppingCart from './shopping-cart.svg'
import signalSlash from './signal-slash.svg'
import signal from './signal.svg'
import sparkles from './sparkles.svg'
import sparkle from './sparkle.svg'
import speakerWave from './speaker-wave.svg'
import speakerXMark from './speaker-x-mark.svg'
import square2Stack from './square-2-stack.svg'
import square3Stack3d from './square-3-stack-3d.svg'
import squares2x2 from './squares-2x2.svg'
import squaresPlus from './squares-plus.svg'
import star from './star.svg'
import stopCircle from './stop-circle.svg'
import stop from './stop.svg'
import sun from './sun.svg'
import swatch from './swatch.svg'
import tableCells from './table-cells.svg'
import tag from './tag.svg'
import ticket from './ticket.svg'
import trash from './trash.svg'
import trophy from './trophy.svg'
import truck from './truck.svg'
import tv from './tv.svg'
import userCircle from './user-circle.svg'
import userGroup from './user-group.svg'
import userMinus from './user-minus.svg'
import userPlus from './user-plus.svg'
import user from './user.svg'
import users from './users.svg'
import variable from './variable.svg'
import videoCameraSlash from './video-camera-slash.svg'
import videoCamera from './video-camera.svg'
import viewColumns from './view-columns.svg'
import viewfinderDot from './viewfinder-dot.svg'
import wallet from './wallet.svg'
import wifi from './wifi.svg'
import window from './window.svg'
import wrenchScrewdriver from './wrench-screwdriver.svg'
import wrench from './wrench.svg'
import xCircle from './x-circle.svg'
import xMark from './x-mark.svg'
import xMarkSmall from './x-mark-small.svg'
import visaHelp from './visa-help.svg'
import diversity from './diversity.svg'
import person from './person.svg'
import pets from './pets.svg'
import connect from './connect.svg'
import dating from './dating.svg'
import events from './events.svg'
import explore from './explore.svg'
import insurance from './insurance.svg'
import journey from './journey.svg'
import living from './living.svg'
import messages from './messages.svg'
import perks from './perks.svg'
import social from './social.svg'
import stamps from './stamps.svg'
import taxes from './taxes.svg'
import visaWizard from './visa-wizard.svg'
import securityUser from './security-user.svg'
import plusIrclePurple from './plus_ircle_purple.svg'
import heartGrey from './heart-grey.svg'
import shareGrey from './share-grey.svg'
import locationIcon from './location-icon.svg'
import dot from './dot.svg'
import miniChevronRight from './minichevron-right.svg'
import ellipsisVerticalGrey from './ellipsis-vertical-grey.svg'
import lockClosedGrey from './lock-closed-grey.svg'
import boltPurple from './bolt-purple.svg'
import clipBoardBlack from './clipboard-document-black.svg'
import facebook from './facebook.svg'
import linkedin from './linkedin.svg'
import twitter from './twitter.svg'
import whatsapp from './whatsapp.svg'
import union from './union.svg'
import switchOff from './switch-off.svg'
import switchOn from './switch-on.svg'
import emailSent from './email-sent.svg'
import arrowRotateCircle from './arrow-rotate-circle.svg'
import buildings2 from './buildings-2.svg'
import simcard from './simcard.svg'
import bubble from './bubble.svg'
import global from './global.svg'
import securityUser2 from './security-user-2.svg'
import coin from './coin.svg'
import illustration from './illustration.svg'
import avatarDefault from './avatar-default.svg'
import stepActive from './step-active.svg'
import step from './step.svg'
import userCheck from './user-check.svg'
import bagLuggage from './bag-luggage.svg'
import emojiSad from './emoji-sad.svg'
import circlExclamation from './circle-exclamation-error.svg'
import circleInfo from './circle-info.svg'
import crossSmall from './cross-small.svg'
import heartBeat from './heart-beat.svg'
import lockedPrivate from './locked-private.svg'
import multiMedia from './multi-media.svg'
import bulletList from './bullet-list.svg'
import xTwitter from './x-twitter.svg'
import telegram from './telegram.svg'
import editBig from './edit-big.svg'
import earthGlobe from './earth-globe.svg'
import passportVisa from './passport-visa.svg'
import plusSmall from './plus-small.svg'
import paperPlane from './paper-plane.svg'
import paperClip2 from './paperclip-2.svg'
import heartLike from './heart-like.svg'
import locationPin from './location-pin.svg'
import peopleCircle from './people-circle.svg'
import warning from './warning.svg'
import infoIcon from './info-Icon.svg'
import circleCheckGreen from './circle-check-green.svg'
import circleCheck from './circle-check.svg'
import buildingsCompany from './buildings-company.svg'
import serverStorage from './server-storage.svg'
import pinFlag from './pin-flag.svg'
import paperPlaneTopRight from './paper-plane-top-right.svg'
import fileBend from './file-bend.svg'

export const solid = {
  avatarDefault,
  stepActive,
  step,
  clipBoardBlack,
  boltPurple,
  lockClosedGrey,
  shareGrey,
  chatBubbleOvalLeftGrey,
  heartGrey,
  ellipsisVerticalGrey,
  miniChevronRight,
  dot,
  locationIcon,
  academicCap,
  adjustmentsHorizontal,
  adjustmentsVertical,
  archiveBoxArrowDown,
  archiveBoxXMark,
  archiveBox,
  arrowDownCircle,
  arrowDownLeft,
  arrowDownOnSquareStack,
  arrowDownOnSquare,
  arrowDownRight,
  arrowDownTray,
  arrowDown,
  arrowLeftCircle,
  arrowLeftOnRectangle,
  arrowLeft,
  arrowLongDown,
  arrowLongLeft,
  arrowLongRight,
  arrowLongUp,
  arrowPathRoundedSquare,
  arrowPath,
  arrowRightCircle,
  arrowRightOnRectangle,
  arrowRight,
  arrowTopRightOnSquare,
  arrowTrendingDown,
  arrowTrendingUp,
  arrowUpCircle,
  arrowUpLeft,
  arrowUpOnSquareStack,
  arrowUpOnSquare,
  arrowUpRight,
  arrowUpTray,
  arrowUp,
  arrowUturnDown,
  arrowUturnLeft,
  arrowUturnRight,
  arrowUturnUp,
  arrowsPointingIn,
  arrowsPointingOut,
  arrowsRightLeft,
  arrowsUpDown,
  atSymbol,
  backspace,
  backward,
  banknotes,
  bars2,
  bars3BottomLeft,
  bars3BottomRight,
  bars3CenterLeft,
  bars3,
  bars4,
  barsArrowDown,
  barsArrowUp,
  battery0,
  battery100,
  battery50,
  beaker,
  bellAlert,
  bellSlash,
  bellSnooze,
  bell,
  boltSlash,
  bolt,
  bookOpen,
  bookmarkSlash,
  bookmarkSquare,
  bookmark,
  briefcase,
  bugAnt,
  buildingLibrary,
  buildingOffice2,
  buildingOffice,
  buildingStorefront,
  cake,
  calculator,
  calendarDays,
  calendar,
  camera,
  chartBarSquare,
  chartBar,
  chartPie,
  chatBubbleBottomCenterText,
  chatBubbleBottomCenter,
  chatBubbleLeftEllipsis,
  chatBubbleLeftRight,
  chatBubbleLeft,
  chatBubbleOvalLeftEllipsis,
  chatBubbleOvalLeft,
  checkBadge,
  checkCircle,
  check,
  chevronDoubleDown,
  chevronDoubleLeft,
  chevronDoubleRight,
  chevronDoubleUp,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUpDown,
  chevronUp,
  circleStack,
  clipboardDocumentCheck,
  clipboardDocumentList,
  clipboardDocument,
  clipboard,
  clock,
  cloudArrowDown,
  cloudArrowUp,
  cloud,
  codeBracketSquare,
  codeBracket,
  cog6Tooth,
  cog8Tooth,
  cog,
  commandLine,
  computerDesktop,
  cpuChip,
  creditCard,
  cubeTransparent,
  cube,
  currencyBangladeshi,
  currencyDollar,
  currencyEuro,
  currencyPound,
  currencyRupee,
  currencyYen,
  cursorArrowRays,
  cursorArrowRipple,
  devicePhoneMobile,
  deviceTablet,
  documentArrowDown,
  documentArrowUp,
  documentChartBar,
  documentCheck,
  documentDuplicate,
  documentMagnifyingGlass,
  documentMinus,
  documentPlus,
  documentText,
  document,
  dotGrid,
  ellipsisHorizontalCircle,
  ellipsisHorizontal,
  ellipsisVertical,
  envelopeOpen,
  envelope,
  exclaimationCircle,
  exclamationTriangle,
  eyeDropper,
  eyeSlash,
  eye,
  faceFrown,
  faceSmile,
  film,
  fingerPrint,
  fire,
  flag,
  folderArrowDown,
  folderMinus,
  folderOpen,
  folderPlus,
  folder,
  forward,
  funnel,
  gif,
  giftTop,
  gift,
  globe,
  globeAmericas,
  globeAsiaAustralia,
  globeEuropeAfrica,
  handRaised,
  handThumbDown,
  handThumbUp,
  hashtag,
  heart,
  homeModern,
  home,
  identification,
  inboxArrowDown,
  inboxStack,
  inbox,
  informationCircle,
  key,
  language,
  lifebuoy,
  lightBulb,
  link,
  listBullet,
  lockClosed,
  lockOpen,
  magnifyingGlassCircle,
  magnifyingGlassMinus,
  magnifyingGlassPlus,
  magnifyingGlass,
  mapPin,
  map,
  megaphone,
  microphone,
  minusCircle,
  minus,
  moon,
  musicalNote,
  newspaper,
  noSymbol,
  paintBrush,
  paperAirplane,
  paperClip,
  pauseCircle,
  pause,
  pencilSquare,
  pencil,
  phoneArrowDownLeft,
  phoneArrowUpRight,
  phoneXMark,
  phone,
  photo,
  playCircle,
  playPause,
  play,
  plusCircle,
  miniPlusCircle,
  plus,
  power,
  presentationChartBar,
  presentationChartLine,
  printer,
  puzzlePiece,
  qrCode,
  questionMarkCircle,
  queueList,
  radio,
  receiptPercent,
  receiptRefund,
  rectangleGroup,
  rectangleStack,
  rocketLaunch,
  rss,
  scale,
  scissors,
  serverStack,
  server,
  share,
  shieldCheck,
  shieldExclamation,
  shoppingBag,
  shoppingCart,
  signalSlash,
  signal,
  sparkles,
  sparkle,
  speakerWave,
  speakerXMark,
  square2Stack,
  square3Stack3d,
  squares2x2,
  squaresPlus,
  star,
  stopCircle,
  stop,
  sun,
  swatch,
  tableCells,
  tag,
  ticket,
  trash,
  trophy,
  truck,
  tv,
  userCircle,
  userGroup,
  userMinus,
  userPlus,
  user,
  users,
  variable,
  videoCameraSlash,
  videoCamera,
  viewColumns,
  viewfinderDot,
  wallet,
  wifi,
  window,
  wrenchScrewdriver,
  wrench,
  xCircle,
  xMark,
  visaHelp,
  diversity,
  pets,
  person,
  connect,
  dating,
  events,
  explore,
  insurance,
  journey,
  living,
  messages,
  perks,
  social,
  stamps,
  taxes,
  visaWizard,
  securityUser,
  plusIrclePurple,
  facebook,
  twitter,
  linkedin,
  whatsapp,
  union,
  switchOff,
  switchOn,
  emailSent,
  arrowRotateCircle,
  buildings2,
  coin,
  bubble,
  global,
  simcard,
  securityUser2,
  illustration,
  userCheck,
  bagLuggage,
  emojiSad,
  xMarkSmall,
  circlExclamation,
  crossSmall,
  circleInfo,
  heartBeat,
  lockedPrivate,
  multiMedia,
  bulletList,
  xTwitter,
  telegram,
  editBig,
  earthGlobe,
  passportVisa,
  plusSmall,
  paperPlane,
  paperClip2,
  heartLike,
  locationPin,
  peopleCircle,
  warning,
  infoIcon,
  circleCheck,
  circleCheckGreen,
  buildingsCompany,
  serverStorage,
  pinFlag,
  paperPlaneTopRight,
  fileBend,
}
